import React, {
    createContext,
    useState,
    useReducer,
    useEffect,
    createRef,
    forwardRef,
    useContext,
    useMemo
} from 'react';
import { connect } from "react-redux"
import { FixedSizeList as List } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';

import './TopTrendsTable.styles.scss';
import KeywordSettings from "@components/KeywordSettings/KeywordSettings";
import { copyToClipboard, makeEnding, toggleInArray } from "@core/old_helpers";
import Cell from "@components/NewAwesomeTable/Cell";
import VisibilitySensor from "react-visibility-sensor";
import CustomScrollbarsVirtualList from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { hasFlavorPermissionSelector, TAKE } from "@selectors/topTrends.selectors";
import TooltipTabs from "@components/TooltipTabs/TooltipTabs";
import {
    IconArrowBottom,
    IconCaretDoubleDown,
    IconCheck, IconCopySimple,
    IconFileSearch,
    IconGear,
    IconSortASC,
    IconSortDESC
} from '@icons';
import { GlobalContext } from '@components/App/App.context';
import Icon from '@components/Icon/Icon';
import cn from "classnames";
import PermissionOverlay from "@components/PermissionOverlay/PermissionOverlay";
import { addNotification } from "@actions/notifications.actions";
import { requestAccess } from "@core/api";

const StickyListContext = createContext();
StickyListContext.displayName = "StickyListContext";

function SortingIcon(
    { direction }
) {
    return (
        <Icon type="small" className="TopTrendsTable__icon">
            {direction === 'asc' ? (
                <IconSortASC/>
            ) : (
                <IconSortDESC/>
            )}
        </Icon>
    );
}

function ActionIcon(
    {
        isDisabled,
        children,
        tooltip = '',
        ...rest
    }
) {
    return (
        <Icon
            type='small'
            className={cn(
                "TopTrendsTable__action-icon",
                !isDisabled && "is-active",
            )}
            {...rest}
        >
            {children}
            {!!tooltip && (
                <div className="TopTrendsTable__action-icon-tooltip">
                    {tooltip}
                </div>
            )}
        </Icon>
    );
}

const itemRenderer = (isSelectable, animatingSelectable, { index, style, data }) => {
    const { setKeywordSettingsState } = data;

    if (!data.rows[index]) {
        return (
            <div className="TopTrendsTable__row" style={style}>
                <Cell
                    className="TopTrendsTable__column"
                    options="bd-right white first load"
                />
                <Cell className="TopTrendsTable__column" options="center load"/>
                <Cell className="TopTrendsTable__column" options="center load"/>
                <Cell className="TopTrendsTable__column" options="center load"/>
                <Cell className="TopTrendsTable__column" options="center hl2 load"/>
                <Cell className="TopTrendsTable__column" options="center hl2 load"/>
                {data.hasFlavorData && (
                    <>
                        <Cell
                            className="TopTrendsTable__column"
                            options="darken center load"
                        />
                        <Cell
                            className="TopTrendsTable__column"
                            options="darken center load"
                        />
                        <Cell
                            className="TopTrendsTable__column"
                            options="darken center last load"
                        />
                    </>
                )}
            </div>
        );
    }

    const { content, keyword } = data.rows[index][0];

    const handleClickKeywordSettings = (e) => {
        const boundingRect = e.target.getBoundingClientRect();
        setKeywordSettingsState({
            isSettingsShown: true,
            settingsX: boundingRect.left + 30,
            settingsY: boundingRect.top + boundingRect.height / 2,
            keyword,
        });
    };
    const checked = data.selectedWords.includes(keyword.name);

    return (
        <div className="TopTrendsTable__row" style={style}>
            <Cell
                className="TopTrendsTable__column"
                options="bd-right white first hv no-pr"
                onClick={() => {
                    isSelectable && data.handleWordSelectedChange(keyword.name)
                }}
            >
                <div style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div className={cn(
                        'TopTrendsTable__checkbox-container',
                        isSelectable && 'TopTrendsTable__checkbox-container--visible',
                        isSelectable && animatingSelectable && 'TopTrendsTable__checkbox-container--showing',
                        !isSelectable && animatingSelectable && 'TopTrendsTable__checkbox-container--hiding',
                    )}>
                        <span
                            className={`
                                TopTrendsTable__checkbox-icon
                                ${checked ? 'TopTrendsTable__checkbox-icon--checked' : ''}
                            `}
                        >
                            <Icon type='small'>
                                <IconCheck/>
                            </Icon>
                        </span>
                    </div>
                    {content}
                </div>
                <div className="TopTrendsTable__actions">
                    <div
                        className="TopTrendsTable__action"
                        onClick={handleClickKeywordSettings}
                    >
                        <ActionIcon
                            isDisabled={keyword.wordExtensions.length === 0}
                            tooltip="includes / excludes"
                        >
                            <IconGear width={14}/>
                        </ActionIcon>
                    </div>
                    <div
                        className="TopTrendsTable__action"
                        onClick={() => data.drill(keyword)}
                    >
                        <ActionIcon
                            tooltip="drill"
                        >
                            <IconCaretDoubleDown width={12}/>
                        </ActionIcon>
                    </div>
                    <div
                        className="TopTrendsTable__action"
                        onClick={() => data.openMenuExamples(keyword)}
                    >
                        <ActionIcon
                            tooltip="menu examples"
                        >
                            <IconFileSearch width={20}/>
                        </ActionIcon>
                    </div>
                </div>
            </Cell>
            <Cell className="TopTrendsTable__column" options="center">
                {data.rows[index][1]}
            </Cell>
            <Cell className="TopTrendsTable__column" options="center">
                {data.rows[index][2]}
            </Cell>
            <Cell className="TopTrendsTable__column" options="center">
                {data.rows[index][3]}
            </Cell>
            <Cell className="TopTrendsTable__column" options="center hl2">
                {parseFloat(data.rows[index][4]) > 0 ? '+' : ''}{data.rows[index][4]}
            </Cell>
            <Cell className="TopTrendsTable__column" options="center hl2">
                {parseFloat(data.rows[index][5]) > 0 ? '+' : ''}{data.rows[index][5]}
            </Cell>
            {data.hasFlavorData && (
                <>
                    <Cell
                        className="TopTrendsTable__column"
                        options="darken center"
                    >
                        {data.rows[index][6]}
                    </Cell>
                    <Cell
                        className="TopTrendsTable__column"
                        options="darken center"
                    >
                        {data.rows[index][7]}
                    </Cell>
                    <Cell
                        className="TopTrendsTable__column"
                        options="darken center last"
                    >
                        {data.rows[index][8]}
                    </Cell>
                </>
            )}
        </div>
    );
};

const YEARS = [1, 2, 3, 4, 5, 10];

function getGrowthPeriodLabel(yearsCount) {
    if (yearsCount === 1) return '12 months';
    return `${yearsCount} ${makeEnding('year', yearsCount)}`;
}

function TopTrendsTable(
    {
        data,
        drill,
        openMenuExamples,
        sortingColumn: sc,
        sortingDirection: sd,
        setSortingColumn,
        loadMore,
        growthPeriod,
        setGrowthPeriod,
        growsMaxYears = 11,
        hasFlavorData,
        hasFlavorPermission,
        hasMoreData,
        selectedCategories,
        tooltipShownInx,
        setTooltipShownInx,
        activeRestaurantListId,
        selectedWords,
        setSelectedWords,
        isSelectable,
        topTrendsCopyTableData,
        addNotification,
        currentCountry,
    }
) {
    const [isYearsDropDownShown, setIsYearsDropDownShown] = useState(false);
    const [animatingSelectable, setAnimatingSelectable] = useState(false);
    const [years, setYears] = useReducer((state, action) => action, []);
    const { getRem } = useContext(GlobalContext);
    const [keywordSettingsState, setKeywordSettingsState] = useReducer(reducer, {
        keyword: {},
        isSettingsShown: false
    });

    const listRef = createRef();

    useEffect(() => {
        listRef.current && listRef.current.scrollToItem(0);
    }, [selectedCategories, activeRestaurantListId, growthPeriod]);

    useEffect(() => {
        setYears(YEARS.filter(year => year < growsMaxYears));
    }, [growsMaxYears]);

    useEffect(() => {
        if ((data.length === 0 && selectedWords.length > 0) || !isSelectable) {
            setSelectedWords([]);
        }
    }, [isSelectable]);

    const showFlavorPermissionOverlay = useMemo(
        () => data.length > 0 && hasFlavorData && !hasFlavorPermission,
        [data, hasFlavorData, hasFlavorPermission]
    );

    const handleRequestAccessButtonClick = () => {
        requestAccess(currentCountry, 'Top Trends');
        addNotification({
            text: "Request sent",
            duration: 4000,
        });
    };

    const handleYearClick = (period) => {
        setGrowthPeriod(period);
        setIsYearsDropDownShown(false);
    };

    function handleWordSelectedChange(id) {
        setSelectedWords(toggleInArray(selectedWords, id));
    }

    const handleLoadMore = (isVisible) => {
        if (isVisible) {
            loadMore();
        }
    };

    let itemsCount = data.length;

    if (hasMoreData) {
        if (data.length === 0) {
            itemsCount += 30;
        } else {
            itemsCount += TAKE;
        }
    }

    const handleCopyTable = () => {
        copyToClipboard(topTrendsCopyTableData);

        addNotification({
            text: topTrendsCopyTableData ? "Successfully copied to your clipboard" : "Something went wrong",
            duration: 2000,
            error: !topTrendsCopyTableData
        })

    };

    useEffect(() => {
        setAnimatingSelectable(true);
        setTimeout(() => setAnimatingSelectable(false), 300);
    }, [isSelectable])

    return (
        <div className={`TopTrendsTable ${hasFlavorData ? '' : 'TopTrendsTable--collapsed'}`}>
            <div className="TopTrendsTable__header">
                <Cell className="TopTrendsTable__column" options="head justify">
                </Cell>
                <Cell
                    className="TopTrendsTable__column" options="center head hv"
                    onClick={() => setSortingColumn(0)}
                    onMouseOver={() => setTooltipShownInx(2)}
                    onMouseOut={() => setTooltipShownInx()}
                >
                    <div className="TopTrendsTable__cell-inner">
                        {sc === 0 && <SortingIcon direction={sd}/>}
                        Penetration
                    </div>
                    <TooltipTabs isShown={tooltipShownInx === 2}><span>% of restaurants</span></TooltipTabs>
                </Cell>
                <Cell
                    className="TopTrendsTable__column" options="center head hv"
                    onClick={() => setSortingColumn(1)}
                    onMouseOver={() => setTooltipShownInx(3)}
                    onMouseOut={() => setTooltipShownInx(null)}
                >
                    <div className="TopTrendsTable__cell-inner">
                        {sc === 1 && <SortingIcon direction={sd}/>}
                        Incidence
                    </div>
                    <TooltipTabs isShown={tooltipShownInx === 3}><span>% of items</span></TooltipTabs>
                </Cell>
                <Cell
                    className="TopTrendsTable__column" options="center head hv"
                    onClick={() => setSortingColumn(2)}
                    onMouseOver={() => setTooltipShownInx(5)}
                    onMouseOut={() => setTooltipShownInx()}
                >
                    <div className="TopTrendsTable__cell-inner">
                        {sc === 2 && <SortingIcon direction={sd}/>}
                        Penetration CT
                    </div>
                    <TooltipTabs isShown={tooltipShownInx === 5}><span># of Restaurants</span></TooltipTabs>
                </Cell>
                <div className="TTTComplexCell TopTrendsTable__column TopTrendsTable__column--wide">
                    <div className="TTTComplexCell__row">
                        <Cell
                            options="center head headest hv"
                            onClick={() => setIsYearsDropDownShown(!isYearsDropDownShown)}
                        >
                            <div className="TopTrendsTable__cell-inner">
                                {getGrowthPeriodLabel(growthPeriod)}
                                <span className={`
                                        TTTComplexCell__dd-icon
                                        ${isYearsDropDownShown ? 'TTTComplexCell__dd-icon--opened' : ''}
                                `}>
                                <Icon type='small'>
                                    <IconArrowBottom/>
                                </Icon>
                            </span>
                            </div>
                        </Cell>
                        {isYearsDropDownShown && (
                            <div className="TTTComplexCell__drop-down">
                                {years.filter(year => year !== growthPeriod).map(year => (
                                    <div className="TTTComplexCell__row">
                                        <Cell
                                            className="TTTComplexCell__dd-item"
                                            options="center head headest hv"
                                            onClick={() => handleYearClick(year)}
                                        >
                                            {getGrowthPeriodLabel(year)}
                                        </Cell>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="TTTComplexCell__row">
                        <Cell
                            className="TTTComplexCell__cell"
                            options="center head header hv"
                            onClick={() => setSortingColumn(4)}
                        >
                            <div className="TopTrendsTable__cell-inner">
                                {sc === 4 && <SortingIcon direction={sd}/>}
                                Past Growth
                            </div>
                        </Cell>
                        <Cell
                            className="TTTComplexCell__cell"
                            options="center head header hv"
                            onClick={() => setSortingColumn(5)}
                            onMouseOver={() => setTooltipShownInx(4)}
                            onMouseOut={() => setTooltipShownInx(null)}
                        >
                            <div className="TopTrendsTable__cell-inner">
                                {sc === 5 && <SortingIcon direction={sd}/>}
                                CAGR

                            </div>
                            <TooltipTabs
                                isShown={tooltipShownInx === 4}><span>Compound Annual Growth Rate</span></TooltipTabs>
                        </Cell>
                    </div>
                </div>
                {hasFlavorData && (
                    <div
                        className="TTTComplexCell TopTrendsTable__column TopTrendsTable__column--wider"
                    >
                        <div
                            className="TTTComplexCell__row"
                            onMouseOver={() => setTooltipShownInx(6)}
                            onMouseOut={() => setTooltipShownInx(null)}
                        >
                            <Cell options="center head header">
                                <div
                                    className="TopTrendsTable__cell-inner"
                                    style={{
                                        opacity: 0.6
                                    }}
                                >
                                    Consumers
                                </div>
                                <TooltipTabs isShown={tooltipShownInx === 6}>
                                    Consumer perceptions are in reference<br/>
                                    to each listed food or flavor <u>in general</u> <br/>
                                    (not relative to a specific category)
                                </TooltipTabs>
                            </Cell>
                        </div>
                        <div className="TTTComplexCell__row">
                            <Cell
                                options={cn(
                                    "center head",
                                    hasFlavorPermission && "hv",
                                )}
                                onClick={hasFlavorPermission ? () => setSortingColumn(6) : null}
                            >
                                <div className="TopTrendsTable__cell-inner">
                                    {sc === 6 && <SortingIcon direction={sd}/>}
                                    Know It
                                </div>
                            </Cell>
                            <Cell
                                options={cn(
                                    "center head",
                                    hasFlavorPermission && "hv",
                                )}
                                onClick={hasFlavorPermission ? () => setSortingColumn(7) : null}
                            >
                                <div className="TopTrendsTable__cell-inner">
                                    {sc === 7 && <SortingIcon direction={sd}/>}
                                    Have Tried It
                                </div>
                            </Cell>
                            <Cell
                                options={cn(
                                    "center head last",
                                    hasFlavorPermission && "hv",
                                )}
                                onClick={hasFlavorPermission ? () => setSortingColumn(8) : null}
                            >
                                <div className="TopTrendsTable__cell-inner">
                                    {sc === 8 && <SortingIcon direction={sd}/>}
                                    Love or Like It
                                </div>
                            </Cell>
                        </div>
                    </div>
                )}
            </div>
            <div className="TopTrendsTable__table">
                <AutoSizer>
                    {({ width, height }) => (
                        <List
                            ref={listRef}
                            height={height}
                            width={width}
                            itemCount={itemsCount}
                            itemSize={getRem(50)}
                            itemData={{
                                rows: data,
                                selectedWords,
                                handleWordSelectedChange,
                                drill,
                                openMenuExamples,
                                hasFlavorData,
                                hasFlavorPermission,
                                handleLoadMore,
                                keywordSettingsState,
                                setKeywordSettingsState,
                            }}
                            outerElementType={CustomScrollbarsVirtualList}
                            innerElementType={forwardRef(({ children, ...props }, ref) => {
                                return (
                                    <div ref={ref} {...props} className="TopTrendsTable__rows">
                                        {children}
                                        {hasMoreData && (
                                            <VisibilitySensor
                                                onChange={handleLoadMore}
                                                partialVisibility={data.length > 0}
                                            >
                                                <div className="TopTrendsTable__loader"/>
                                            </VisibilitySensor>
                                        )}
                                    </div>
                                );
                            })}
                        >
                            {itemRenderer.bind(this, isSelectable, animatingSelectable)}
                        </List>
                    )}
                </AutoSizer>
                {showFlavorPermissionOverlay && (
                    <div
                        className="TopTrendsTable__flavor-permission-overlay"
                        style={{
                            height: getRem(50 * data.length),
                        }}
                    >
                        <PermissionOverlay
                            handleButtonClick={handleRequestAccessButtonClick}
                            variant={data.length < 3 ? 'row narrow' : undefined}
                            hideIcon={data.length <= 3}
                        />
                    </div>
                )}
            </div>
            <KeywordSettings
                searchingKeyword={keywordSettingsState.keyword.name}
                readOnly={true}
                x={keywordSettingsState.settingsX}
                y={keywordSettingsState.settingsY}
                closeModal={() => setKeywordSettingsState({ isSettingsShown: false })}
                shown={keywordSettingsState.isSettingsShown}
                includes={(keywordSettingsState.keyword.wordExtensions || []).filter(item => item.wordType === 1 || item.wordType === 3)}
                excludes={(keywordSettingsState.keyword.wordExtensions || []).filter(item => item.wordType === 2)}
            />
            <div
                className="TopTrendsTable__copy-table-btn"
            >
                <ActionIcon
                    tooltip="copy table"
                    onClick={handleCopyTable}
                >
                    <IconCopySimple/>
                </ActionIcon>
            </div>
        </div>
    );
}

const reducer = (state, action) => {
    return { ...state, ...action }
};

// const selectedWordsReducer = (state, action) => {
//     return [...action];
// };

TopTrendsTable.defaultProps = {
    includedKeywords: [{ id: 0, name: "asdaad" }, { id: 1, name: "asdfgfd" }, { id: 2, name: "vbcs" }],
    excludedKeywords: [{ id: 0, name: "45645634" }],
};
export default connect(state => ({
    selectedCategories: state.topTrends.selectedCategories,
    activeRestaurantListId: state.filters.activeRestaurantListId,
    hasFlavorPermission: hasFlavorPermissionSelector(state),
    topTrendsCopyTableData: state.topTrends.copyTableData,
    currentCountry: state.countries.current,
}), {
    addNotification,
})(TopTrendsTable);