import { ID } from '@models';
import useFiltersData from '@apiHooks/useFilters';
import { useEffect, useMemo, useState } from 'react';
import { FilterSettings } from '@models/FilterSettings';
import useRestaurantsList from '@apiHooks/useRestaurantsList';

export default function useFilterSettings (countryCode: ID, restaurantListId: number = 0) {
    const [localRestaurantListId, setLocalRestaurantListId] = useState(restaurantListId);
    const filtersData = useFiltersData(countryCode);
    const restaurantsList = useRestaurantsList(countryCode, localRestaurantListId);

    const filterSettings = useMemo(() => {
        if (!!filtersData.data) {
            const { filters, words } = filtersData.data;

            return new FilterSettings({
                apiModel: {
                    ...filters?.find(filter => filter.restaurantListIds?.includes(localRestaurantListId)),
                    words: words || [],
                    restaurants: restaurantsList.data || [],
                    restaurantListId: localRestaurantListId,
                },
            });
        }
        return new FilterSettings();
    }, [filtersData, restaurantsList, localRestaurantListId]);

    useEffect(() => {
        setLocalRestaurantListId(restaurantListId || filtersData.data?.defaultRestaurantListId || 0);
    }, [restaurantListId, filtersData]);

    return {
        ...filtersData,
        data: filterSettings,
    }
}