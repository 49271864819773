import React, { useReducer, useState, useEffect, } from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import Modal from "@components/Modal/Modal";
import VenuesForm from "@components/VenuesForm/VenuesForm";
import MenuItems from "@components/MenuItems/MenuItems";
import Attributes from "@components/Attributes/Attributes";
import { filtersModelSelector } from "@selectors/filters.selectors";
import "./FiltersContainer.scss";
import { apply as applyFilters } from "@actions/filters.actions";
import { getFiltersValues } from "@core/filters.helpers";
import AdditionalFilters from "./AdditionalFilters";
import { currentCurrencySelector } from "@selectors/countries.selector";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import Tabs from "@components/Tabs/Tabs";
import { Button } from "@datassential/platform-ui-lib";
import { deepEqual } from "@core/old_helpers";

const FiltersTabs = {
    Venues: 1,
    Attributes: 2,
    MenuItems: 3,
    AdditionalFilters: 4,
    Tags: 5,
};

const FiltersContainer = (
    {
        shown,
        onClose,
        filters,
        applyFilters,
        keywords,
        restaurants,
        currentCurrency,
        permissions = {},
    }
) => {
    const [selectedFilterTabId, setSelectedFilterTabId] = useState(FiltersTabs.Venues);
    const [localFilters, setLocalFilters] = useReducer(reducer, initialLocalFilters);
    const [isFiltersBeenChanged, setIsFiltersBeenChanged] = useState(false);

    useEffect(() => {
        setIsFiltersBeenChanged(!deepEqual(filters, localFilters));
    }, [filters, localFilters]);

    useEffect(() => {
        if (shown) {
            setLocalFilters(filters);
        }
    }, [shown, filters]);

    const apply = () => {
        applyFilters(getFiltersValues(localFilters));
        onClose();
    };

    const handleChange = (section, fieldName, newValue) => {
        setLocalFilters({
            ...localFilters,
            [section]: {
                ...localFilters[section],
                [fieldName]: newValue,
            }
        });
    };

    const handleCheckboxGroupChange = (section, fieldName, id, isGroup) => {
        const field = localFilters[section][fieldName];
        let newValue;
        if (id === 'all') {
            newValue = field.map(option => ({ ...option, isChecked: !field.every(option => option.isChecked) }));
        } else if (isGroup) {
            const groupValue = !field.filter(option => option.groupId === id).every(option => option.isChecked);
            newValue = field.map(option => ({
                ...option,
                isChecked: option.groupId === id ? groupValue : option.isChecked,
            }));
        } else {
            newValue = field.map(option => ({
                ...option,
                isChecked: option.id === id ? !option.isChecked : option.isChecked,
            }));
        }
        handleChange(section, fieldName, newValue);
    };

    const handleCheckAllMenuItems = () => {
        const isAllChecked = localFilters.menuItems.appetizers.every(option => option.isChecked) &&
            localFilters.menuItems.entrees.every(option => option.isChecked) &&
            localFilters.menuItems.sides.every(option => option.isChecked) &&
            localFilters.menuItems.desserts.every(option => option.isChecked) &&
            localFilters.menuItems.beverages.every(option => option.isChecked);
        setLocalFilters({
            ...localFilters,
            menuItems: {
                ...localFilters.menuItems,
                appetizers: localFilters.menuItems.appetizers.map(option => ({ ...option, isChecked: !isAllChecked })),
                entrees: localFilters.menuItems.entrees.map(option => ({ ...option, isChecked: !isAllChecked })),
                sides: localFilters.menuItems.sides.map(option => ({ ...option, isChecked: !isAllChecked })),
                desserts: localFilters.menuItems.desserts.map(option => ({ ...option, isChecked: !isAllChecked })),
                beverages: localFilters.menuItems.beverages.map(option => ({ ...option, isChecked: !isAllChecked })),
            },
        });
    };

    const addItems = (section, fieldName, items) => {

        const field = localFilters[section][fieldName];
        handleChange(section, fieldName, [...field, ...items]);
    };

    const removeItem = (section, fieldName, itemName) => {
        const field = localFilters[section][fieldName];
        const itemIndex = field.findIndex(item => item.name.toLowerCase() === itemName.toLowerCase());

        handleChange(section, fieldName, [
            ...field.slice(0, itemIndex),
            ...field.slice(itemIndex + 1)
        ]);
    };

    return (
        <Modal closeModal={onClose} shown={shown} hasOverlay>
            <div className="Popup__wrapper">
                <div className="Popup Popup--large FiltersContainer">
                    <div className="FiltersContainer__header">
                        <Tabs
                            title={'Place'}
                            data={[
                                { id: FiltersTabs.Venues, name: 'Venues' },
                                { id: FiltersTabs.Attributes, name: 'Attributes' },
                            ]}
                            activeItemId={selectedFilterTabId}
                            handleClick={setSelectedFilterTabId}
                            className="is-place"
                            testId="selectFiltersPopupNavPlace"
                        />
                        <Tabs
                            title={'Food'}
                            data={[
                                { id: FiltersTabs.MenuItems, name: 'Item Types' },
                                { id: FiltersTabs.AdditionalFilters, name: 'Other' },
                            ]}
                            activeItemId={selectedFilterTabId}
                            handleClick={setSelectedFilterTabId}
                            className="is-food"
                            testId="selectFiltersPopupNavFood"
                        />
                        <div
                            className="FiltersContainer__tabs-tooltip is-place"
                            data-testid="blockFiltersPopupNavigationTooltipPlace"
                        >
                            <div
                                className="FiltersContainer__tabs-tooltip-title"
                                data-testid="textFiltersPopupNavigationTooltipPlaceTitle"
                            >
                                <span>PLACE</span> filters let you filter on the <span>menus</span> to include in your search
                            </div>
                            <div data-testid="textFiltersPopupNavigationTooltipPlaceText-0">
                                For example, the "BBQ" cuisine filter will narrow your search to all BBQ restaurants.
                            </div>
                            <div data-testid="textFiltersPopupNavigationTooltipPlaceText-1">
                                Note: this won't include BBQ menu items at other types of places, like American or
                            </div>
                            <div data-testid="textFiltersPopupNavigationTooltipPlaceText-2">
                                Korean restaurants.
                            </div>
                        </div>
                        <div
                            className="FiltersContainer__tabs-tooltip is-food"
                            data-testid="blockFiltersPopupNavigationTooltipFood"
                        >
                            <div
                                className="FiltersContainer__tabs-tooltip-title"
                                data-testid="textFiltersPopupNavigationTooltipFoodTitle"
                            >
                                <span>FOOD</span> filters let you filter on the <span>menu items</span> to include in your search
                            </div>
                            <div data-testid="textFiltersPopupNavigationTooltipFoodText-0">
                                For example, using the "Burger" item type will filter on all burgers, whether they
                            </div>
                            <div data-testid="textFiltersPopupNavigationTooltipFoodText-1">
                                appear on an American, Steakhouse, or any other type of menu.
                            </div>
                        </div>
                    </div>
                    <div className="FiltersContainer__content">
                        {selectedFilterTabId === FiltersTabs.Venues && Object.keys(localFilters.venues).length > 0 && (
                            <VenuesForm
                                data={localFilters.venues}
                                restaurants={restaurants}
                                addRestaurants={(items) => addItems('venues', 'restaurants', items)}
                                removeRestaurant={(index) => removeItem('venues', 'restaurants', index)}
                                handleChange={(...args) => handleChange('venues', ...args)}
                                handleCheckboxGroupChange={(...args) => handleCheckboxGroupChange('venues', ...args)}
                                isFiltersBeenChanged={isFiltersBeenChanged}
                            />
                        )}
                        {selectedFilterTabId === FiltersTabs.Attributes && Object.keys(localFilters.attributes).length > 0 && (
                            <Attributes
                                data={localFilters.attributes}
                                handleChange={(...args) => handleChange('attributes', ...args)}
                                handleCheckboxGroupChange={(...args) => handleCheckboxGroupChange('attributes', ...args)}
                            />
                        )}
                        {selectedFilterTabId === FiltersTabs.MenuItems && Object.keys(localFilters.menuItems).length > 0 && (
                            <MenuItems
                                setIsAdditionalFiltersOpen={() => null}
                                data={localFilters.menuItems}
                                handleChange={(...args) => handleChange('menuItems', ...args)}
                                handleCheckAll={handleCheckAllMenuItems}
                                handleCheckboxGroupChange={(...args) => handleCheckboxGroupChange('menuItems', ...args)}
                                currentCurrency={currentCurrency}
                            />
                        )}
                        {selectedFilterTabId === FiltersTabs.AdditionalFilters && localFilters.menuItems.words && (
                            <AdditionalFilters
                                addKeywords={(words) => addItems('menuItems', 'words', words)}
                                removeKeyword={(...args) => removeItem('menuItems', 'words', ...args)}
                                keywords={keywords}
                                words={localFilters.menuItems.words}
                                minPrice={localFilters.menuItems.minPrice}
                                maxPrice={localFilters.menuItems.maxPrice}
                                currentCurrency={currentCurrency}
                                handleChange={(...args) => handleChange('menuItems', ...args)}
                            />
                        )}
                    </div>
                    <div className="FiltersContainer__footer">
                        <Button
                            className="FiltersContainer__footer-button"
                            modifiers={["naked"]}
                            onClick={onClose}
                            data-testid="buttonFiltersPopupCancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="FiltersContainer__footer-button"
                            onClick={apply}
                            data-testid="buttonFiltersPopupApply"
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const initialLocalFilters = {
    venues: {},
    menuItems: {},
    attributes: {},
};

const reducer = (state, action) => {
    return { ...state, ...action };
};

const mapStateToProps = state => ({
    filters: filtersModelSelector(state),
    keywords: state.keywords.data,
    restaurants: state.filters.activeRestaurantList,
    permissions: currentCountryWithPermissionsSelector(state),
    currentCurrency: currentCurrencySelector(state),
});

const mapDispatchToProps = dispatch => ({
    applyFilters: data => dispatch(applyFilters(data)),

});

FiltersContainer.propTypes = {
    isFiltersPopupShown: PT.bool,
    onClose: PT.func,
    applyFilters: PT.func,
    filters: PT.object,
    keywords: PT.array,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersContainer);
