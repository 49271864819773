import React from 'react'
import { getRoundValue } from "@core/old_helpers"
import './ProfileItem.scss'

const FoodProfileItem = ({title = '', value = ''}) => {
    return (
        <div className='profile-item'>
            <span className='profile-item__title profile-item__val'>{title}</span>
            <span className='profile-item__value profile-item__val'>{getRoundValue(value)}</span>
        </div>
    )
};

export default FoodProfileItem;