import { createSelector } from "reselect";
import { SLICES } from "@core/models/ByoTableData";
import { dynamicSort } from "@core/old_helpers";
import { currentCountrySelector } from "./countries.selector"

const itemTypesColumnsOrder = SLICES.find(({ id }) => id === 1006).columnsOrder;

export const segmentsSortedArray = [
    'qsr',
    'fast casual',
    'midscale',
    'casual dining',
    'fine dining'
];

export const rawDataSelector = state => state.filters.raw;
export const activeFiltersSelector = state => state.filters.active;
export const restaurantListsSelector = state => state.filters.restaurantLists;
export const rawRestaurantListsSelector = state => state.filters.restaurantLists;
export const activeRestaurantListIdSelector = state => state.filters.activeRestaurantListId;
export const defaultRestaurantListIdSelector = state => state.filters.defaultRestaurantListId;
export const customRestaurantListsSelector = state => state.filters.customRestaurantLists;

export const activeRestaurantListNameSelector = createSelector(
    activeRestaurantListIdSelector,
    restaurantListsSelector,
    customRestaurantListsSelector,
    (activeRestaurantListId, restaurantLists, customRestaurantLists) => {
        if (!activeRestaurantListId || restaurantLists.length === 0) return '';
        return [...restaurantLists, ...customRestaurantLists].find(({ id }) => id === activeRestaurantListId).name;
    }
);

export const filtersDataSelector = createSelector(
    activeRestaurantListIdSelector,
    rawDataSelector,
    defaultRestaurantListIdSelector,
    (activeListId, rawData, defaultRestaurantListId) => {
        const getData = (id) => {
            return rawData.find(({ restaurantListIds }) => restaurantListIds.includes(id))
        };

        const data = getData(activeListId);

        if ( !data ) {
            return getData(defaultRestaurantListId);
        }

        return data;
    }
);

export const filtersModelSelector = createSelector(
    filtersDataSelector,
    activeFiltersSelector,
    (filters, active) => {
        if (!filters) {
            return {
                venues: {},
                menuItems: {},
                attributes: {}
            };
        }
        const rawFilters = filters;
        if (!rawFilters.otherFilters) {
            rawFilters.otherFilters = {};
        }
        const {
            maxUnits = null,
            minPrice = null,
            maxPrice = null,
            ethnicSpeciality = [],
        } = rawFilters.otherFilters;
        // const result = {
        return {
            venues: {
                segments: rawFilters.restaurantFilters.segments
                    .map((item, inx) => ({
                        ...item,
                        isChecked: active && active.venues.segments ? active.venues.segments.includes(item.id) : false,
                    }))
                    .sort((a, b) => {
                        return segmentsSortedArray.indexOf(a.name.toLowerCase()) - segmentsSortedArray.indexOf(b.name.toLowerCase());
                    }),
                regions: rawFilters.restaurantFilters.regions.map((item, inx) => ({
                    ...item,
                    isChecked: active && active.venues.regions ? active.venues.regions.includes(item.id) : false,
                })),
                cuisines: rawFilters.restaurantFilters.cuisines.map((item, inx) => ({
                    ...item,
                    isChecked: active && active.venues.cuisines ? active.venues.cuisines.includes(item.id) : false,
                })),
                hostedLocations: rawFilters.restaurantFilters.hostedLocations.map((item, inx) => ({
                    ...item,
                    isChecked: active && active.venues.hostedLocations ? active.venues.hostedLocations.includes(item.id) : false,
                })),
                restaurants: (active && active.venues.restaurants) ? active.venues.restaurants : [],
                minUnits: active ? active.venues.minUnits : '1',
                maxUnits: active ? active.venues.maxUnits : maxUnits
            },
            menuItems: {
                ...rawFilters.menuPartFilterItems.reduce((obj, curr, idx) => {
                    const key = `${curr.name.toLowerCase()}s`;
                    obj[key] = rawFilters.menuPartFilterItems[idx].menuItemTypes.map(i => ({
                        ...i,
                        isChecked: active && active.menuItems[key]
                            ? active.menuItems[key].includes(i.id)
                            : false
                    }));
                    obj[key].sort((a, b) => {
                        const ai = itemTypesColumnsOrder.indexOf(a.id);
                        const bi = itemTypesColumnsOrder.indexOf(b.id);
                        return ai < bi ? -1 : 1;
                    });
                    return obj;
                }, {}),
                words: active ? active.menuItems.words.sort(dynamicSort("name", "asc")) : [],
                minPrice: active ? active.menuItems.minPrice : minPrice,
                maxPrice: active ? active.menuItems.maxPrice : maxPrice,
            },
            attributes: {
                dayParts: rawFilters.restaurantFilters.dayParts.map((item) => ({
                    ...item,
                    isChecked: active && active.attributes.dayParts ? active.attributes.dayParts.includes(item.id) : false,
                })),
                ethnicSpeciality: ethnicSpeciality.map(item => ({
                    ...item,
                    isChecked: active && active.attributes.ethnicSpeciality
                        ? active.attributes.ethnicSpeciality.includes(item.id)
                        : false,
                })),
                healthyTerms: active ? active.attributes.healthyTerms : false,
                smallPlates: active ? active.attributes.smallPlates : false,
                kidMenus: active ? active.attributes.kidMenus : false,
                glutenFree: active ? active.attributes.glutenFree : false,
                plantBased: active ? active.attributes.plantBased : false,
                catering: active ? active.attributes.catering : false,
            }
        };
    }
);

export const restaurantsListsSelector = createSelector(
    rawRestaurantListsSelector,
    activeRestaurantListIdSelector,
    customRestaurantListsSelector,
    (restaurantsLists, activeRestaurantListId, customRestaurantLists) => [...restaurantsLists, ...customRestaurantLists].map((list) => ({
        ...list,
        isActive: list.id === activeRestaurantListId,
    }))
);

export const filtersForBackEndSelector = createSelector(
    currentCountrySelector,
    activeFiltersSelector,
    filtersDataSelector,
    activeRestaurantListIdSelector,
    activeRestaurantListNameSelector,
    (currentCountry, activeFilters, rawFilters, activeRestaurantListId, activeRestaurantListName) => {
        if (!activeFilters || rawFilters.length === 0) return {};
        const restaurantFilters = {
            restaurantListId: activeRestaurantListId,
            restaurantListName: activeRestaurantListName,
        };
        Object.keys(rawFilters.restaurantFilters).forEach((filterKey) => {
            restaurantFilters[filterKey] = rawFilters.restaurantFilters[filterKey]
                .map(({ id, name }) => ({ id, name }))
                .filter(({ id }) => activeFilters.venues[filterKey] ? activeFilters.venues[filterKey].includes(id) : activeFilters.attributes[filterKey].includes(id));
        });
        const menuPartFilterItems = rawFilters.menuPartFilterItems.map(({ id, name, menuItemTypes }) => ({
            id,
            name,
            menuItemTypes: menuItemTypes
                .map(({ id, name }) => ({ id, name }))
                .filter(({ id }) => activeFilters.menuItems[`${name.toLowerCase()}s`].includes(id)),
        }));

        const { ethnicSpeciality = [] } = rawFilters.otherFilters;

        const otherFilters = {
            healthyDining: activeFilters.attributes.healthyTerms,
            smallPlates: activeFilters.attributes.smallPlates,
            kidMenus: activeFilters.attributes.kidMenus,
            glutenFree: activeFilters.attributes.glutenFree,
            plantBased: activeFilters.attributes.plantBased,
            ethnicSpeciality:
                ethnicSpeciality.map(({ id, name }) => ({ id, name }))
                    .filter(
                        ({ id }) => activeFilters.attributes.ethnicSpeciality.includes(id)
                    ),
            catering: activeFilters.attributes.catering,
            minPrice: activeFilters.menuItems.minPrice,
            maxPrice: activeFilters.menuItems.maxPrice,
            minUnits: +activeFilters.venues.minUnits === 1 ? null : +activeFilters.venues.minUnits,
            maxUnits: +activeFilters.venues.maxUnits || null,
            restaurants: activeFilters.venues.restaurants,
            customWords: activeFilters.menuItems.words,
        };
        return {
            countryCode: currentCountry,
            restaurantFilters,
            menuPartFilterItems,
            otherFilters,
        };
    }
);

export const filtersChangesSelector = createSelector(
    filtersModelSelector,
    ({ venues, menuItems, attributes }) => {
        let isSomeFieldChanged = false;
        let changedFieldsCount = 0;
        let lastChangedFieldName = null;
        const changes = {
            venues: [],
            menuItems: [],
            attributes: [],
        };

        if (!Object.keys(venues).length || !Object.keys(menuItems).length || !Object.keys(attributes).length) {
            return {
                isSomeFieldChanged,
                changedFieldsCount,
                lastChangedFieldName,
                changes,
            };
        }
        const {
            segments,
            hostedLocations,
            regions,
            cuisines,
            restaurants,
            minUnits,
            maxUnits,
        } = venues;
        const {
            appetizers,
            entrees,
            sides,
            desserts,
            beverages,
            words,
            minPrice,
            maxPrice,
        } = menuItems;
        const {
            dayParts,
            ethnicSpeciality,
            healthyTerms,
            smallPlates,
            kidMenus,
            glutenFree,
            plantBased,
            catering,
        } = attributes;



        const optionNotChecked = option => !option.isChecked;
        const optionChecked = option => option.isChecked;
        const addCondition = (condition, fieldName, section) => {
            isSomeFieldChanged = isSomeFieldChanged || condition;
            if (condition) {
                changedFieldsCount++;
                lastChangedFieldName = fieldName;
                changes[section].push(fieldName);
            }
        };

        if (segments.length > 0) addCondition(segments.some(optionChecked), 'segments', 'venues');
        if (hostedLocations.length > 0) addCondition(hostedLocations.some(optionChecked), 'segments', 'venues');
        if (regions.length > 0) addCondition(regions.some(optionChecked), 'regions', 'venues');
        if (cuisines.length > 0) addCondition(cuisines.some(optionChecked), 'cuisines', 'venues');
        addCondition(restaurants.length > 0, 'restaurants', 'venues');
        addCondition(+minUnits !== 1, 'minUnits', 'venues');
        addCondition(maxUnits !== null, 'maxUnits', 'venues');

        if (appetizers.length > 0) addCondition(appetizers.some(optionChecked), 'appetizers', 'menuItems');
        if (entrees.length > 0) addCondition(entrees.some(optionChecked), 'entrees', 'menuItems');
        if (sides.length > 0) addCondition(sides.some(optionChecked), 'sides', 'menuItems');
        if (desserts.length > 0) addCondition(desserts.some(optionChecked), 'desserts', 'menuItems');
        if (beverages.length > 0) addCondition(beverages.some(optionChecked), 'beverages', 'menuItems');
        addCondition(words.length > 0, 'words', 'menuItems');
        addCondition(minPrice !== null, 'minPrice', 'menuItems');
        addCondition(maxPrice !== null, 'maxPrice', 'menuItems');

        if (dayParts.length > 0) addCondition(dayParts.some(optionChecked), 'day parts', 'attributes');
        if (ethnicSpeciality.length > 0) addCondition(ethnicSpeciality.every(optionChecked), 'ethnic speciality', 'attributes');
        addCondition(healthyTerms === true, 'healthy terms', 'attributes');
        addCondition(smallPlates === true, 'small plates', 'attributes');
        addCondition(kidMenus === true, 'kid menus', 'attributes');
        addCondition(glutenFree === true, 'gluten free', 'attributes');
        addCondition(plantBased === true, 'plant based', 'attributes');
        addCondition(catering !== false, 'catering', 'attributes');

        return {
            isSomeFieldChanged,
            changedFieldsCount,
            lastChangedFieldName,
            changes,
        };
    }
);

export const isVenuesChangedSelector = createSelector(
    filtersChangesSelector,
    ({ changes }) => changes.venues.length > 0
);
export const isMenuItemsChangedSelector = createSelector(
    filtersChangesSelector,
    ({ changes }) => changes.menuItems.length > 0
);
export const isAttributesChangedSelector = createSelector(
    filtersChangesSelector,
    ({ changes }) => changes.attributes.length > 0
);

export const filtersTextSelector = createSelector(
    filtersChangesSelector,
    filtersChanges => {
        console.log('filtersChanges', filtersChanges);
        const {isSomeFieldChanged, changedFieldsCount, lastChangedFieldName} = filtersChanges;
        if (!isSomeFieldChanged) {
            return '';
        } else if (changedFieldsCount === 1) {
            return `your results are currently filtered by ${lastChangedFieldName}`;
        } else {
            return 'your results have multiple filters applied';
        }
    }
);