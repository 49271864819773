import React, { useState, useEffect, useMemo } from "react";
import { connect } from 'react-redux';
import PT from "prop-types";
import { CSSTransition } from "react-transition-group";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import Dropdown from "./Dropdown/Dropdown";
import "./MenuItems.scss";
import { setData as watcherFiltersChanging } from '@actions/filters.actions'
import cn from "classnames";

const MenuItems = (
    {
        data,
        handleCheckAll,
        handleCheckboxGroupChange,
        watcherFiltersChanging,
        fullLayout = false,
        tooltipsData,
    }
) => {
    const [activeItemTypeId, setActiveItemTypeId] = useState(0);

    const activeItemType = useMemo(() => {
        if (activeItemTypeId === 0 || tooltipsData.length === 0) return null;
        return tooltipsData.find(item => item.id === activeItemTypeId);
    }, [tooltipsData, activeItemTypeId]);

    const {
        appetizers,
        entrees,
        sides,
        desserts,
        beverages

    } = data;

    //sorted beverages
    beverages.push(...beverages.splice(beverages.findIndex(v => v.name === 'Other Bev'), 1));

    const [ visibleDropdownId, setDropdownVisible ] = useState(-1);
    useEffect(() => {
        if (visibleDropdownId >= 0) {
            document.addEventListener("click", closeDropdown, true);
        } else {
            document.removeEventListener("click", closeDropdown, true);
        }
    }, [ visibleDropdownId ]);

    useEffect(() => {
        watcherFiltersChanging({
            isMenuItemsFilterBeenChanged: [ ...appetizers, ...entrees, ...sides, ...desserts, ...beverages ].every(el => el.isChecked)
        });
    }, [ data ]);

    const closeDropdown = ({target}) => {
        const dropdownElement = document.querySelector(".menu-items-dropdown-enter-done");
        if (dropdownElement && !dropdownElement.contains(target)) {
            setDropdownVisible(-1);
        }
    };

    const showDropdown = index => {
        setDropdownVisible(index);
    };

    const isAllChecked = data.appetizers.every(option => option.isChecked) &&
        data.entrees.every(option => option.isChecked) &&
        data.sides.every(option => option.isChecked) &&
        data.desserts.every(option => option.isChecked) &&
        data.beverages.every(option => option.isChecked);


    const includesCount = data.words.filter(({wordType}) => wordType !== 2).length;
    const excludesCount = data.words.filter(({wordType}) => wordType === 2).length;



    return (
        <div className={`menu-items ${fullLayout ? 'menu-items--is-full': ''}`}>
            <div className="menu-items__sub-header">
                <span
                    className={`menu-items__select-btn ${!isAllChecked ? "menu-items__select-btn--active" : ""}`}
                    onClick={handleCheckAll}
                    data-testid="buttonFiltersPopupMenuItemsClearAll"
                >
                    clear all
                </span>
                &nbsp;/&nbsp;
                <span
                    className={`menu-items__select-btn ${isAllChecked ? "menu-items__select-btn--active" : ""}`}
                    onClick={handleCheckAll}
                    data-testid="buttonFiltersPopupMenuItemsSelectAll"
                >
                    select all
                </span>
            </div>
            <div className="menu-items__header">
                <div className="menu-items__col">
                    <div className="menu-items__checkbox-container">
                        <CheckboxInput
                            isBlueCheck
                            onChange={() => handleCheckboxGroupChange('appetizers', 'all')}
                            labelText="Appetizers"
                            isChecked={data.appetizers.every(option => option.isChecked) && data.appetizers.length > 0}
                            isPartialChecked={data.appetizers.some(option => option.isChecked) && !data.appetizers.every(option => option.isChecked)}
                            hasArrowIcon={data.appetizers.length > 0}
                            isArrowDown={visibleDropdownId === 1}
                            disabled={data.appetizers.length === 0}
                            onClick={() => showDropdown(1)}
                            testId="inputFiltersPopupMenuItemsCheckboxAppetizersAll"
                        />
                    </div>
                    {data.appetizers.length > 0 && (
                        <div
                            className={cn("menu-items__text",
                                data.appetizers.filter(option => option.isChecked).length > 0 && 'menu-items__text--selected'
                            )}
                            data-testid="textFiltersPopupMenuItemsAppetizersSelected"
                        >
                            {data.appetizers.every(option => option.isChecked)
                                ? 'All selected'
                                : `${data.appetizers.filter(option => option.isChecked).length} of ${data.appetizers.length} selected`
                            }
                        </div>
                    )}
                    <CSSTransition
                        in={visibleDropdownId === 1}
                        timeout={400}
                        classNames="menu-items-dropdown"
                    >
                        <Dropdown
                            arrowLeft="5%"
                            options={data.appetizers}
                            handleChange={(id) => handleCheckboxGroupChange('appetizers', id)}
                            onItemMouseEnter={setActiveItemTypeId}
                            onItemMouseLeave={() => setActiveItemTypeId(0)}
                        />
                    </CSSTransition>
                </div>
                <div className="menu-items__col">
                    <div className="menu-items__checkbox-container">
                        <CheckboxInput
                            isBlueCheck
                            onChange={() => handleCheckboxGroupChange('entrees', 'all')}
                            labelText="Entrees"
                            isChecked={data.entrees.every(option => option.isChecked) && data.entrees.length > 0}
                            isPartialChecked={data.entrees.some(option => option.isChecked) && !data.entrees.every(option => option.isChecked)}
                            hasArrowIcon={data.entrees.length > 0}
                            isArrowDown={visibleDropdownId === 2}
                            disabled={data.entrees.length === 0}
                            onClick={() => showDropdown(2)}
                            testId="inputFiltersPopupMenuItemsCheckboxEntreesAll"
                        />
                    </div>
                    {data.entrees.length > 0 && (
                        <div
                            className={cn(
                                "menu-items__text",
                                data.entrees.filter(option => option.isChecked).length > 0 && 'menu-items__text--selected',
                            )}
                            data-testid="textFiltersPopupMenuItemsEntreesSelected"
                        >
                            {data.entrees.every(option => option.isChecked)
                                ? 'All selected'
                                : `${data.entrees.filter(option => option.isChecked).length} of ${data.entrees.length} selected`
                            }
                        </div>
                    )}
                    <CSSTransition
                        in={visibleDropdownId === 2}
                        timeout={400}
                        classNames="menu-items-dropdown"
                    >
                        <Dropdown
                            arrowLeft="23%"
                            options={data.entrees}
                            handleChange={(id) => handleCheckboxGroupChange('entrees', id)}
                            onItemMouseEnter={setActiveItemTypeId}
                            onItemMouseLeave={() => setActiveItemTypeId(0)}
                        />
                    </CSSTransition>
                </div>
                <div className="menu-items__col">
                    <div className="menu-items__checkbox-container">
                        <CheckboxInput
                            isBlueCheck
                            onChange={() => handleCheckboxGroupChange('sides', 'all')}
                            labelText="Sides"
                            isChecked={data.sides.every(option => option.isChecked) && data.sides.length > 0}
                            isPartialChecked={data.sides.some(option => option.isChecked) && !data.sides.every(option => option.isChecked)}
                            hasArrowIcon={data.sides.length > 0}
                            isArrowDown={visibleDropdownId === 3}
                            disabled={data.sides.length === 0}
                            onClick={() => showDropdown(3)}
                            testId="inputFiltersPopupMenuItemsCheckboxSidesAll"
                        />
                    </div>
                    {data.sides.length > 0 && (
                        <div
                            className={cn(
                                "menu-items__text",
                                data.sides.filter(option => option.isChecked).length > 0 && 'menu-items__text--selected'
                            )}
                            data-testid="textFiltersPopupMenuItemsSidesSelected"
                        >
                            {data.sides.every(option => option.isChecked)
                                ? 'All selected'
                                : `${data.sides.filter(option => option.isChecked).length} of ${data.sides.length} selected`
                            }
                        </div>
                    )}
                    <CSSTransition
                        in={visibleDropdownId === 3}
                        timeout={400}
                        classNames="menu-items-dropdown"
                    >
                        <Dropdown
                            arrowLeft="42%"
                            options={data.sides}
                            handleChange={(id) => handleCheckboxGroupChange('sides', id)}
                            onItemMouseEnter={setActiveItemTypeId}
                            onItemMouseLeave={() => setActiveItemTypeId(0)}
                        />
                    </CSSTransition>
                </div>
                <div className="menu-items__col">
                    <div className="menu-items__checkbox-container">
                        <CheckboxInput
                            isBlueCheck
                            onChange={() => handleCheckboxGroupChange('desserts', 'all')}
                            labelText="Desserts"
                            isChecked={data.desserts.every(option => option.isChecked) && data.desserts.length > 0}
                            isPartialChecked={data.desserts.some(option => option.isChecked) && !data.desserts.every(option => option.isChecked)}
                            hasArrowIcon={data.desserts.length > 0}
                            isArrowDown={visibleDropdownId === 4}
                            disabled={data.desserts.length === 0}
                            onClick={() => showDropdown(4)}
                            testId="inputFiltersPopupMenuItemsCheckboxDessertsAll"
                        />
                    </div>
                    {data.desserts.length > 0 && (
                        <div
                            className={cn(
                                "menu-items__text",
                                data.desserts.filter(option => option.isChecked).length > 0 && 'menu-items__text--selected'
                            )}
                            data-testid="textFiltersPopupMenuItemsDessertsSelected"
                        >
                            {data.desserts.every(option => option.isChecked)
                                ? 'All selected'
                                : `${data.desserts.filter(option => option.isChecked).length} of ${data.desserts.length} selected`
                            }
                        </div>
                    )}
                    <CSSTransition
                        in={visibleDropdownId === 4}
                        timeout={400}
                        classNames="menu-items-dropdown"
                    >
                        <Dropdown
                            arrowLeft="61%"
                            options={data.desserts}
                            handleChange={(id) => handleCheckboxGroupChange('desserts', id)}
                            onItemMouseEnter={setActiveItemTypeId}
                            onItemMouseLeave={() => setActiveItemTypeId(0)}
                        />
                    </CSSTransition>
                </div>
                <div className="menu-items__col">
                    <div className="menu-items__checkbox-container">
                        <CheckboxInput
                            isBlueCheck
                            onChange={() => handleCheckboxGroupChange('beverages', 'all')}
                            labelText="Beverages"
                            isChecked={data.beverages.every(option => option.isChecked) && data.beverages.length > 0}
                            isPartialChecked={data.beverages.some(option => option.isChecked) && !data.beverages.every(option => option.isChecked)}
                            hasArrowIcon={data.beverages.length > 0}
                            isArrowDown={visibleDropdownId === 5}
                            disabled={data.beverages.length === 0}
                            onClick={() => showDropdown(5)}
                            testId="inputFiltersPopupMenuItemsCheckboxBeveragesAll"
                        />
                    </div>
                    {data.beverages.length > 0 && (
                        <div
                            className={cn(
                                "menu-items__text",
                                data.beverages.filter(option => option.isChecked).length > 0 && 'menu-items__text--selected'
                            )}
                            data-testid="textFiltersPopupMenuItemsBeveragesSelected"
                        >
                            {data.beverages.every(option => option.isChecked)
                                ? 'All selected'
                                : `${data.beverages.filter(option => option.isChecked).length} of ${data.beverages.length} selected`
                            }
                        </div>
                    )}
                    <CSSTransition
                        in={visibleDropdownId === 5}
                        timeout={400}
                        classNames="menu-items-dropdown"
                    >
                        <Dropdown
                            arrowLeft="81%"
                            options={data.beverages}
                            handleChange={(...args) => handleCheckboxGroupChange('beverages', ...args)}
                            withGroups={true}
                            onItemMouseEnter={setActiveItemTypeId}
                            onItemMouseLeave={() => setActiveItemTypeId(0)}
                        />
                    </CSSTransition>
                </div>

            </div>

            <div className="menu-items__content">
                <div className="menu-items__pre-footer">
                    <div className="menu-items__pre-footer-title">
                        {activeItemType && activeItemType.definition.toLowerCase()}
                    </div>
                    <div className="menu-items__pre-footer-description">
                        {activeItemType && `Examples: ${activeItemType.examples.toLowerCase()}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

MenuItems.propTypes = {
    toggleItem: PT.func,
    data: PT.shape({
        appetizers: PT.array,
        entrees: PT.array,
        sides: PT.array,
        desserts: PT.array,
        beverages: PT.array
    }),
    isShownFirst: PT.bool,
    setShownFirst: PT.func,
    toggleGroup: PT.func,
};

export default connect(state => ({
    tooltipsData: state.filters.tooltipsData,
}), {watcherFiltersChanging})(MenuItems);
