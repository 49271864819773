import React, { useState, useEffect, createRef, useContext } from 'react';
import FoodProfileItem from '../FoodProfileItem/FoodProfileItem.jsx';
import { Link } from "react-router-dom"
import './FoodProfileItemList.scss';
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import CustomScrollbarsVirtualList from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList"
import Loader from "../Loader/Loader";
import NoMatches from "../NoMatches/NoMatches";
import { GlobalContext } from '@components/App/App.context';

const FoodProfileItemList = ({ data = [], checked, isLoading, searchValue }) => {
    const { getRem } = useContext(GlobalContext);
    const [columnCount, setColumnCount] = useState(4);

    const listRef = createRef();

    let tableData = [];

    if (data.length > 0) {
        tableData = data.map(el => {
            let topTrenCategory;
            if (Array.isArray(el.topTrenCategory)) {
                topTrenCategory = el.topTrenCategory
            } else {
                topTrenCategory = [];
            }
            return ({
                ...el,
                topTrenCategory
            })
        })
            .filter(({ topTrenCategory }) => !checked.length || topTrenCategory.some(id => checked.includes(id)))
            .filter(el => !searchValue || el.name.toLocaleLowerCase().match(searchValue.toLocaleLowerCase()))
    }

    const windowResize = () => {
        if (window.innerWidth > 1800) {
            setColumnCount(5);
        }
        if (window.innerWidth < 1800) {
            setColumnCount(4);
        }
        if (window.innerWidth < 1475) {
            setColumnCount(3);
        }
        if (window.innerWidth < 1024) {
            setColumnCount(2);
        }
    };

    useEffect(() => {
        windowResize();
        window.addEventListener('resize', windowResize);

        return () => {
            window.removeEventListener('resize', windowResize);
        }
    }, []);

    const Row = ({ index, style }) => {
        const sliceFrom = index * columnCount;
        const sliceTo = (index + 1) * columnCount;
        const dataForRow = tableData.slice(sliceFrom, sliceTo);
        return (
            <div
                className="FoodProfileItemList__row"
                style={style}
            >
                {dataForRow.map((el) => {
                    return (
                        <Link
                            to={`/food-profile?id=${el.id}&name=${encodeURIComponent(el.name)}`}
                            className="FoodProfileItemList__link"
                            key={el.id}
                            style={{ width: `${100/columnCount}%` }}
                        >
                            <FoodProfileItem
                                title={el.name}
                                value={el.penetration}
                            />

                        </Link>
                    )
                })
                }
            </div>
        )
    };

    return (
        <div className='FoodProfileItemList'>
            <div className='FoodProfileItemList__header'>
                <p className='FoodProfileItemList__left'>
                    <span className='FoodProfileItemList__title'>Profiles </span>
                    <span className='FoodProfileItemList__subtitle'>(penetration%)</span>
                </p>
            </div>
            <div className="FoodProfileItemList__wrap">
                {tableData.length === 0 && data.length > 0 && <NoMatches/>}
                <AutoSizer>
                    {({ height = 0, width }) => {
                        return (
                            <List
                                ref={listRef}
                                className="List"
                                height={height}
                                itemCount={Math.ceil(tableData.length / columnCount)}
                                itemSize={getRem(45)}
                                width={width}
                                outerElementType={CustomScrollbarsVirtualList}
                            >
                                {Row}
                            </List>
                        )
                    }
                    }
                </AutoSizer>
                <Loader isShown={isLoading}/>
            </div>
        </div>

    )
};

export default FoodProfileItemList;
