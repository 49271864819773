import React, { useState, useEffect, useLayoutEffect } from "react";
import PT from "prop-types";
import "./SearchField.scss";
import AutoComplete from "../AutoComplete/AutoComplete";

const SearchField = ({
    searchQuery = "",
    setSearchQuery,
    onEnter = () => {},
    autoCompleteData = [],
    searchError,
    isDisabled = false,
    modifiers = [],
    inputAutoFocus = true,
    isUpperCase = false,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [activeOptionIndex, setActiveOptionIndex] = useState(-1);

    useLayoutEffect(() => {
        setInputValue(searchQuery);
        setActiveOptionIndex(-1);
    }, [searchQuery]);

    useEffect(() => {
        if (activeOptionIndex >= 0) {
            setInputValue(autoCompleteData[activeOptionIndex] && autoCompleteData[activeOptionIndex].name);
        }
    }, [activeOptionIndex]);

    const handleKeyDown = event => {
        if (searchQuery.length > 0 && event.keyCode === 13) {
            setSearchQuery(inputValue);
            onEnter();
        }
        if (searchQuery.length > 2) {
            if (event.key === "ArrowDown") {
                setActiveOptionIndex(
                    activeOptionIndex === autoCompleteData.length - 1
                        ? 0
                        : activeOptionIndex + 1
                );
            } else if (event.key === "ArrowUp") {
                setActiveOptionIndex(
                    activeOptionIndex === 0
                        ? autoCompleteData.length - 1
                        : activeOptionIndex - 1
                );
            }
        }
    };

    const classList = new Set([
        "search-field",
        searchError ? "search-field--error" : "",
        ...modifiers.map(i => `search-field--${i}`),
    ]);

    return (
        <div
            className={Array.from(classList).join(" ")}
        >
            <input
                type="text"
                className="search-field__input"
                placeholder="Search something..."
                value={inputValue}
                onChange={({ target }) => setSearchQuery(isUpperCase ? target.value.toUpperCase() : target.value)}
                onKeyDown={handleKeyDown}
                autoFocus={inputAutoFocus}
                disabled={isDisabled}
            />
            <div className={`search-field__error ${searchError ? " search-field__error--shown" : ""}`}>
                The keyword is already in the list
            </div>
            {searchQuery.length > 0 && (
                <button
                    onClick={() => setSearchQuery("")}
                    className="search-field__clear"
                />
            )}
            <AutoComplete
                data={autoCompleteData}
                activeOptionIndex={activeOptionIndex}
                onClick={word => {
                    setSearchQuery(word.name);
                    onEnter();
                }}
                onMouseMove={index => {
                    setActiveOptionIndex(index);
                }}
                query={searchQuery}
                isUpperCase={isUpperCase}
            />
        </div>
    );
};

SearchField.propTypes = {
    searchQuery: PT.string,
    setSearchQuery: PT.func,
    onEnter: PT.func,
    autoCompleteData: PT.arrayOf(
        PT.shape({
            id: PT.number,
            name: PT.string,
        })
    ),
    chartsCount: PT.number,
    searchError: PT.bool,
};

export default SearchField;
