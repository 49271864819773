import React, { useRef, useEffect, useState, useReducer, useContext } from 'react';
import Cell from "@components/NewAwesomeTable/Cell";
import Flag from "@components/Flag/Flag";
import HiddenCountriesPopup from '@components/HiddenCountriesPopup/HiddenCountriesPopup'
import { GlobalContext } from '@components/App/App.context';

function TableRowOption({ data }) {
    const { getRem } = useContext(GlobalContext);
    const [visibleFlagsAmount, setVisibleFlagsAmount] = useState(0);
    const hiddenFlagAmountLength = data.countryCodes.length - visibleFlagsAmount;
    const flagsCell = useRef(null);
    const flagCounterWidth = getRem(68);
    const flagWidth = getRem(80);

    useEffect(() => {
        if(flagsCell.current) {
            setVisibleFlagsAmount(  Math.floor((flagsCell.current.offsetWidth - flagCounterWidth) / flagWidth));
        }
    }, [flagsCell.current]);

    const [state, dispatch] = useReducer(reducer, initialState);
    const handleClickHiddenCountriesCounter = (e) => {
        const boundingRect = e.target.getBoundingClientRect();
        dispatch({
            x: boundingRect.right + 15,
            y: boundingRect.top,
            shown: true
        })
    };

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--empty AdminTable__col--regular"/>
            <Cell className="AdminTable__col AdminTable__col--fluid" ref={flagsCell} options="no-p">
                {data.countryCodes.slice(0, visibleFlagsAmount).map(country => (<Flag key={country.countryCode} {...country}/>))}
                {hiddenFlagAmountLength > 0 &&
                    /* TODO: create personal class name */
                    ( <div
                            onMouseEnter={(e) => handleClickHiddenCountriesCounter(e)}
                            onMouseLeave={() => dispatch({shown: false})}
                            className='AdminTable__counter'>
                        + {hiddenFlagAmountLength} more
                    </div>
                    )
                }
                <HiddenCountriesPopup
                    title='Master group'
                    countryCodes={data.countryCodes.slice(visibleFlagsAmount)}
                    closeModal={() => dispatch({shown: false})}
                    {...state}/>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular"/>
                {data.properties.map((property, index) => {

                    return (
                        <Cell
                            key={index}
                            className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p"
                            options="center"
                        >
                            <div
                                className={`
                                    AdminTable__label
                                    AdminTable__label--${property ? "Yes" : "No"}
                                `}
                            />
                            {property ? "Yes" : "No"}
                        </Cell>
                    )
                })}
            <Cell className="AdminTable__col AdminTable__col--regular"/>
        </>
    );
}

const initialState = {
    x: 0,
    y: 0,
    shown: false,
};
const reducer = (state, action) => ({
    ...state,
    ...action
});

export default TableRowOption;