import React, { useState, useEffect } from 'react';
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import "./Popup.styles.scss";

function Popup(
    {
        closeModal,
        shown,
        modifier,
        title,
        onConfirm,
        children,
        btnModifier,
        applyBtnTitle,
        hasConfirmData = false,
    }
) {
    const [dataToConfirm, setDataToConfirm] = useState(null);

    useEffect(() => {
        setDataToConfirm(null)
    }, [shown]);

    const handleConfirm = () => {
        onConfirm(dataToConfirm);
        closeModal();
    };

    const content = hasConfirmData ? children({
        ...children.props,
        dataToConfirm, setDataToConfirm,
    }) : children;

    return (
        <Modal closeModal={closeModal} shown={shown} hasOverlay>
            <div
                className="Popup__wrapper"
                onClick={(event) => event.stopPropagation() }>
                <div className={`Popup Popup--${modifier}`}>
                    <div className="Popup__title">{title}</div>
                    <div className="Popup__content">
                        {content}
                    </div>
                    <div className="Popup__btn-container">
                        <Button
                            onClick={closeModal}
                            modifiers={['transparent']}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            modifiers={[`${btnModifier}`]}
                        >
                            {applyBtnTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Popup;