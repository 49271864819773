import React, { useState } from 'react';
import { connect } from 'react-redux'
import Cell from "@components/NewAwesomeTable/Cell";
import { Link } from "react-router-dom";
import { getFormattedThousand, getRoundValue } from "@core/old_helpers";
import { deleteAdminKeyword } from '@core/api'
import { Routes } from "@routes";
import { addNotification } from "@actions/notifications.actions";
import serializeParams from "@core/serializeParams";
import Popup from "@components/Popup/Popup";
import HightlightChars from '@pages/Admin/Common/HightlightChars/HightlightChars';
import { IconDelete, IconEdit } from '@icons';
import Icon from '@components/Icon/Icon';

// import './TableRowKeyword.styles.scss';

function TableRowKeyword({ data, getAllAdminKeywords, setKeywordsData, addNotification }) {
    const [ isDeleting, setIsDeleting ] = useState(false);

    const handleRemoveKeyword = () => {
        deleteAdminKeyword(data.id).then(res => {
            getAllAdminKeywords().then(setKeywordsData);
            addNotification({
                text: "The keyword is removed. The keywords list will be updated in a few seconds",
                duration: 4000,
            })
        })
    };

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--no-pr AdminTable__col--small">
                {data.id}
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--fluid"
            >
                <HightlightChars name={data.renderName} />
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--justify AdminTable__col--no-p AdminTable__col--regular" options="center">
                <div>{getRoundValue(data.totalUSPenetrationPercent)}</div>
                <div>{getFormattedThousand(data.totalUSPenetrationCount)}</div>
            </Cell>
            {data.isExpanded  && (
                <Cell className="AdminTable__col AdminTable__col--col5" options="center no-p">
                    Hide details
                </Cell>
            )}
            {!data.isExpanded && data.properties && data.properties.map((property, index) => {
                return (
                    <Cell
                        key={index}
                        className="AdminTable__col AdminTable__col--no-p AdminTable__col--regular"
                        options="center"
                    >
                        <div
                            className={`
                                    AdminTable__label
                                    AdminTable__label--${property ? "Yes" : "No"}
                                `}
                        />
                        {property ? "Yes" : "No"}
                    </Cell>
                )
            })}
            <Cell className="AdminTable__col AdminTable__col--no-p AdminTable__col--regular" options="center">
                <Link
                    to={{
                        pathname: Routes.AdminKeywordEdit,
                        search: serializeParams({
                            id: data.id,
                            name: data.name,
                        })
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="AdminTable__btn">
                    <Icon type="small">
                        <IconEdit />
                    </Icon>
                </Link>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsDeleting(true);
                    }}
                    className="AdminTable__btn">
                    <Icon type="small">
                        <IconDelete />
                    </Icon>
                </button>
            </Cell>
            <Popup
                shown={isDeleting}
                closeModal={() => setIsDeleting(false)}
                title={`Delete a keyword "${data.name.toUpperCase()}"`}
                btnModifier="red"
                applyBtnTitle="Delete"
                onConfirm={handleRemoveKeyword}
            >
                <div className="Popup__text">
                    Are you sure you want to delete <span>{data.name.toUpperCase()}</span> and all related keyword settings?
                </div>
            </Popup>
        </>
    );
}

export default connect(state => ({}),{ addNotification })(TableRowKeyword);