import {
    cssX,
    IDropdownColumn,
    markMatch,
    SearchBox,
    SearchBoxLoading,
    SearchBoxNoResults,
    useUuid
} from "@datassential/platform-ui-lib";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchResults } from "@apiHooks/useSearchResults";
import { iSearchResultsSection, ItemTypeEnum, SearchResults } from "@models/SearchResults";
import { useDispatch, useSelector } from "react-redux";
import { currentCountrySelector } from "@selectors/countries.selector";
import { ID, ReportFileExtension } from "@models";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "@routes";
import { ExportTypeEnum } from "@models/ExportTypeEnum";
import http from "@core/http";
import apiConfig from "@apiConfig";
import { iNotification } from "@models/Noification";
import { addNotification } from "@actions/notifications.actions";
import { v4 as uuid} from 'uuid';
import cn from "classnames";

const MIN_RESULTS_COUNT = 3;

const HeaderSearch = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const id = useUuid();
    const [query, setQuery] = useState('');
    const [selectedItemTypeId, setSelectedItemTypeId] = useState<ID | null>(null);
    const currentCountry = useSelector(currentCountrySelector);

    const searchResults = useSearchResults(currentCountry, query);
    const { isLoading, data } = query.trim() ? searchResults : { isLoading: false, data: new SearchResults() };

    const pushNotification = useCallback(
        (notification: iNotification) => dispatch(addNotification(notification)),
        [dispatch]
    );

    const downloadSummaryReport = useCallback(
        (reportId: ID, eTypeOfExport: ExportTypeEnum) => {
            const msgId = uuid();
            pushNotification({
                text: "Downloading...",
                id: msgId,
            });
            http.downloadFileXHRFromUrl(
                'GET',
                apiConfig.get('export report', { reportId, eTypeOfExport, countryCode: currentCountry })
            ).then(() => {
                pushNotification({
                    text: "Download was successful",
                    duration: 4000,
                    id: msgId,
                });
            });
        },
        [currentCountry, pushNotification]
    )

    const downloadCategoryReport = useCallback(
        (reportId: ID, eTypeOfExport: ReportFileExtension) => {
            const msgId = uuid();
            pushNotification({
                text: "Downloading...",
                id: msgId,
            });
            http.downloadFileXHRFromUrl(
                'GET',
                apiConfig.get('category report', { reportId, eTypeOfExport, countryCode: currentCountry })
            ).then(() => {
                pushNotification({
                    text: "Download was successful",
                    duration: 4000,
                    id: msgId,
                });
            });
        },
        [currentCountry, pushNotification]
    );

    const mapResultsSection = useCallback(
        (section: iSearchResultsSection): IDropdownColumn  => {
            const isOpened = selectedItemTypeId === section.id;

            return {
                id: section.id,
                title: section.title.toUpperCase(),
                items: section.items.slice(0, isOpened ? Infinity : MIN_RESULTS_COUNT).map((item, index) => {
                    const props = {
                        className: cn(
                            cssX('Dropdown__item'),
                            "PlatformHeader__search-item",
                        ),
                        children: (<>
                            <span
                                className="PlatformHeader__search-item-title"
                                data-testid="textHeaderSearchResultTitle"
                                dangerouslySetInnerHTML={{
                                    __html: markMatch(item.name, query.trim()),
                                }}
                            />
                            &nbsp;
                            <span
                                className="PlatformHeader__search-item-sub-title"
                                data-testid="textHeaderSearchResultSubTitle"
                            >
                                {SearchResults.getItemSubTitle(item, index, section.id)}
                            </span>
                        </>),
                    };

                    return {
                        id: item.id,
                        content: (<>
                            {section.id === ItemTypeEnum.FoodProfile && (
                                <div {...props}/>
                            )}
                            {section.id !== ItemTypeEnum.FoodProfile && (
                                <Link
                                    to={SearchResults.getItemLink(item, section.id)}
                                    {...props}
                                />
                            )}
                            {section.id === ItemTypeEnum.FoodProfile && (
                                <div className={cssX('Dropdown__item-actions')}>
                                    <Link
                                        to={SearchResults.getItemLink(item, section.id)}
                                        className={cn(
                                            cssX('Dropdown__item-action'),
                                            'PlatformHeader__search-action',
                                            'is-medium',
                                        )}
                                        data-testid="buttonHeaderSearchResultFoodTrend"
                                    >
                                        Trend
                                    </Link>
                                    {item.isFoodProfile && (
                                        <Link
                                            className={cn(
                                                cssX('Dropdown__item-action'),
                                                'PlatformHeader__search-action',
                                                'is-medium',
                                            )}
                                            data-testid="buttonHeaderSearchResultFoodProfile"
                                            to={`${Routes.foodProfile}?id=${item.id}&name=${encodeURIComponent(item.name)}`}
                                        >
                                            Profile
                                        </Link>
                                    )}
                                </div>
                            )}
                            {section.id === ItemTypeEnum.Report && (
                                <div className={cssX('Dropdown__item-actions')}>
                                    <div
                                        className={cn(
                                            cssX('Dropdown__item-action'),
                                            'PlatformHeader__search-action',
                                            'is-small',
                                        )}
                                        data-testid="buttonHeaderSearchReportPdf"
                                        onClick={() => downloadSummaryReport(item.id, ExportTypeEnum.Pdf)}
                                    >
                                        PDF
                                    </div>
                                    <div
                                        className={cn(
                                            cssX('Dropdown__item-action'),
                                            'PlatformHeader__search-action',
                                            'is-small',
                                        )}
                                        data-testid="buttonHeaderSearchReportPpt"
                                        onClick={() => downloadSummaryReport(item.id, ExportTypeEnum.Ppt)}
                                    >
                                        PPT
                                    </div>
                                </div>
                            )}
                            {section.id === ItemTypeEnum.CategoryReport && (
                                <div className={cssX('Dropdown__item-actions')}>
                                    <div
                                        className={cn(
                                            cssX('Dropdown__item-action'),
                                            'PlatformHeader__search-action',
                                            'is-small',
                                        )}
                                        data-testid="buttonHeaderSearchCategoryReportPdf"
                                        onClick={() => downloadCategoryReport(item.id, ReportFileExtension.Pdf)}
                                    >
                                        PDF
                                    </div>
                                    <div
                                        className={cn(
                                            cssX('Dropdown__item-action'),
                                            'PlatformHeader__search-action',
                                            'is-small',
                                        )}
                                        data-testid="buttonHeaderSearchCategoryReportPpt"
                                        onClick={() => downloadCategoryReport(item.id, ReportFileExtension.Ppt)}
                                    >
                                        PPT
                                    </div>
                                </div>
                            )}
                        </>),
                    }
                }),
                content: section.items.length > MIN_RESULTS_COUNT && (
                    <div
                        className={cssX("Dropdown__more-results")}
                        onClick={() => setSelectedItemTypeId(isOpened ? null : section.id)}
                        data-testid="buttonHeaderSearchCategoryMoreResults"
                    >
                        {isOpened ? 'HIDE' : 'MORE'}
                    </div>
                ),
            }
        },
        [selectedItemTypeId, query, downloadSummaryReport, downloadCategoryReport]
    );

    useEffect(() => {
        setSelectedItemTypeId(null);
    }, [setQuery]);

    const searchAutocompleteGroups = useMemo(
        (): IDropdownColumn[] => {
            return data.data
                .filter(i => i.items.length > 0)
                .filter(i => {
                    if (selectedItemTypeId) {
                        return i.id === selectedItemTypeId;
                    }

                    return true;
                })
                .map(mapResultsSection);
        },
        [data.data, mapResultsSection, selectedItemTypeId]
    );

    const children = useMemo(
        () => {
            if (isLoading) return (
                <SearchBoxLoading/>
            );

            if (!isLoading && searchAutocompleteGroups?.length === 0) return (
                <SearchBoxNoResults/>
            );

            return null;
        },
        [isLoading, searchAutocompleteGroups]
    );

    const hasData = useMemo(
        () => !isLoading && searchAutocompleteGroups.length > 0,
        [isLoading, searchAutocompleteGroups]
    );

    return (
        <SearchBox
            testId={"selectHeaderSearch"}
            tooltipId={id}
            inputProps={{
                value: query,
                setValue: (value: string) => {
                    setQuery(value);
                    setSelectedItemTypeId(null);
                },
                onSubmit: () => {
                    history(`${Routes.byo}?words=${query}`);
                    setQuery('');
                },
                placeholder: 'Search in Menu Trends',
                testId: 'inputHeaderSearch',
            }}
            dropdownProps={{
                isOpened: query.trim() !== '',
                groups: hasData ? searchAutocompleteGroups : undefined,
                id,
                testId: "selectHeaderSearchDropdown",
                isColTitlesSticky: true,
                style: {
                    maxHeight: 300,
                    overflowX: 'hidden',
                },
                children: query !== '' ? children : null,
            }}
        />
    );
}

export default HeaderSearch;