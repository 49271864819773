import { string } from 'prop-types';

export function getThousandAlias (n: number): string {
    let result;
    if (n >= 1000000) {
        result = `${+(n / 1000000).toFixed(1)}M `;
    }
    else if (n >= 1000) {
        result = `${+(n / 1000).toFixed(1)}K `;
    }
    else {
        result = `${n}`;
    }
    return result;
}


export function getFormattedThousand(number: number = 0, separator: string = ','): string {
    if (number < 0) return `-${getFormattedThousand(-number)}`;
    let numberString = Math.trunc(number).toString();
    let result = "";
    while (numberString.length > 3) {
        result = `${separator}${numberString.substr(numberString.length - 3)}${result}`;
        numberString = numberString.substr(0, numberString.length - 3);
    }

    result = `${numberString}${result}`;
    return result;
}

export function getDecimal(n: number): number {
    const int = parseInt(`${n}`);
    return n - int;
}

export function getDecimalAfterPoint (n: number, decimalSize: number = 2): string {
    return getDecimal(n).toFixed(decimalSize).replace(/^-?0./, '');
}

export function getNumberWithDecimal(n: number, decimalSize: number = 1): string {
    const points = getDecimalAfterPoint(n, decimalSize);
    return Number.parseInt(points) ? `${n}` : `${n}.${points}`;
}

export function getFormattedThousandWithDecimal(n: number, decimalSize: number = 2): string {
    return `${getFormattedThousand(n || 0)}.${getDecimalAfterPoint(n || 0, decimalSize)}`;
}

export function getAverage (arr: number[]) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
        sum += arr[i];
    }

    return sum / arr.length;
}

export function getFormattedPrice(value: number): string {
    const hasDecimals = value - Math.floor(value) > 0;
    return hasDecimals ? value.toFixed(2) : `${value}`;
}

export const getFilenameFromContentDisposition = (disposition: string | null): string => {
    let filename = "";
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        const matches = disposition.match(filenameRegex);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
}

export function downloadFile(url: string, filename?: string): void {
    const link = document.createElement('a');

    if (typeof link.download === 'undefined') {
        window.location.href = url;
    } else {
        link.href = url;
        link.download = filename || "true";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

type NavigatorIncludesMS = Navigator & { msSaveBlob?: (blob: Blob, filename?: string) => void };

export function downloadBlobFile(blob: Blob, filename?: string) {
    const navigator = window.navigator as NavigatorIncludesMS;

    if (navigator.msSaveBlob && typeof navigator.msSaveBlob !== 'undefined') {
        navigator.msSaveBlob(blob, filename);
    } else {
        let URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            downloadFile(downloadUrl, filename);
        } else {
            window.location.href = downloadUrl;
        }

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);
    }
}

export function deepEqual<A, B>(a: A, b: B): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function prepareForSorting(value: any): string | number {
    switch (typeof value) {
        case "string": return value.toLowerCase();
        case "boolean": return value ? 1 : 0;
        default: return value;
    }
}

export function highlightMatches(str: string, searchQuery: string): string {
    const searchRegExp = new RegExp(`(${escapeRegExp(searchQuery.trim())})`, "gi");
    if (searchQuery.trim() !== "") {
        str = str.replace(searchRegExp, "<b>$1</b>");
    }
    return str;
}

export function minusArrayUnique(a1: any[], a2: any[]) {
    return a1.filter(i => !a2.includes(i));
}

export function uniteArraysUnique(a1: any[], a2: any[]) {
    return Array.from(new Set([...a1, ...a2]));
}

export function isInRange(val: number, leftEdge: number, rightEdge: number): boolean {
    return val >= leftEdge && val <= rightEdge;
}

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}