import React, { FC } from 'react';
import SubTitle from '@components/SubTitle/SubTitle';
import { FilterSettings, iFilterSettings, iOptionWithGroup } from '@models/FilterSettings';
import { iFilterValue } from '@models/FilterValue';
import './AdminFilters.styles.scss';
import FilterCheckboxGroup from '@pages/Admin/Common/AdminFilters/FilterCheckboxGroup';
import FilterCheckboxAccordion from '@pages/Admin/Common/AdminFilters/FilterCheckboxAccordion';
import MultipleInput from '@components/MultipleInput/MultipleInput';
import FilterRangeUnits from '@pages/Admin/Common/AdminFilters/FilterRangeUnits';
import { equalInLC } from '@core/old_helpers';
import FilterCheckboxGroupDropdown from '@pages/Admin/Common/AdminFilters/FilterCheckboxGroupDropdown';
import AdditionalFilters from '@containers/FiltersContainer/AdditionalFilters';
import CheckboxInput from '@components/CheckboxInput/CheckboxInput';
import ToggleInput from '@components/ToggleInput/ToggleInput';
import AutoWidthInput from '@components/AutoWidthInput/AutoWidthInput';

export const SectionTitle: FC = ({ children }) => {
    return (
        <div className="AdminFilters__title">
            <SubTitle textColor="yellow">{children}</SubTitle>
        </div>
    );
};



const AdminFilters: FC<{
    settings: iFilterSettings;
    data: iFilterValue;
    onChange: (value: iFilterValue) => void;
    disabledFields?: Array<keyof iFilterValue>;
    currentCurrency: string;
}> = ({
    settings,
    data,
    onChange,
    disabledFields = [],
    currentCurrency,
}) => {
    const handleChange = (field: keyof iFilterValue) => (value: any) => {
        onChange({
            ...data,
            [field]: value,
        });
    }

    const handleRemoveListItem = (field: keyof iFilterValue) => (name: string) => {
        const list = data[field];

        if (Array.isArray(list)) {
            handleChange(field)((list as Array<any>).filter(i => !equalInLC(i.name, name)));
        }
    };

    const isHealthyTermsEnabled = !data.smallPlates && !data.kidMenus;
    const isGlutenFreeEnabled = !data.smallPlates && !data.kidMenus;
    const isPlantBasedEnabled = !data.smallPlates && !data.kidMenus;
    const isSmallPlatesEnabled = !data.healthyDining && !data.plantBased && !data.glutenFree && !data.kidMenus;
    const isKidMenusEnabled = !data.healthyDining && !data.plantBased && !data.glutenFree && !data.smallPlates;

    return (
        <div className="AdminFilters">
            <SectionTitle>Venues settings</SectionTitle>
            <div className="AdminFilters__section">
                {settings.segments.length > 0 && (
                    <FilterCheckboxGroup
                        isDisabled={disabledFields?.includes('segments')}
                        title="Segments"
                        options={settings.segments}
                        value={data.segments}
                        onChange={handleChange('segments')}
                    />
                )}
                {settings.hostedLocations.length > 0 && (
                    <FilterCheckboxGroup
                        isDisabled={disabledFields?.includes('hostedLocations')}
                        title="Segments"
                        options={settings.hostedLocations}
                        value={data.hostedLocations}
                        onChange={handleChange('hostedLocations')}
                    />
                )}
                {settings.regions.length > 0 && (
                    <FilterCheckboxGroup
                        isDisabled={disabledFields?.includes('regions')}
                        title="Regions"
                        options={settings.regions}
                        value={data.regions}
                        onChange={handleChange('regions')}
                    />
                )}
                {settings.cuisines.length > 0 && (
                    <FilterCheckboxAccordion
                        title="Cuisines"
                        options={settings.cuisines}
                        value={data.cuisines}
                        onChange={handleChange('cuisines')}
                    />
                )}
                <div className="AdminFilters__column size-xl">
                    <div>Specific restaurant</div>
                    <br/>
                    <MultipleInput
                        submittedItems={data.restaurants}
                        onSubmit={(restaurants: FilterSettings['restaurants']) => handleChange('restaurants')([
                            ...data.restaurants,
                            ...restaurants,
                        ])}
                        removeItem={handleRemoveListItem('restaurants')}
                        keywords={settings.restaurants}
                        modifiers={["filters", 'light', 'medium']}
                        onlyFromList
                        placeholder="Type a restaurant"
                    />
                </div>
            </div>
            <div className="AdminFilters__section">
                <FilterRangeUnits
                    minUnits={data.minUnits}
                    maxUnits={data.maxUnits}
                    onChange={handleChange}
                />
            </div>
            <SectionTitle>Menu Items settings</SectionTitle>
            <div className="AdminFilters__section">
                {settings.appetizers.length > 0 && (
                    <FilterCheckboxGroupDropdown
                        indexInRow={0}
                        title="Appetizers"
                        options={settings.appetizers as iOptionWithGroup[]}
                        value={data.menuItemTypes}
                        onChange={handleChange('menuItemTypes')}
                    />
                )}
                {settings.entries.length > 0 && (
                    <FilterCheckboxGroupDropdown
                        indexInRow={1}
                        title="Entrees"
                        options={settings.entries as iOptionWithGroup[]}
                        value={data.menuItemTypes}
                        onChange={handleChange('menuItemTypes')}
                    />
                )}
                {settings.sides.length > 0 && (
                    <FilterCheckboxGroupDropdown
                        indexInRow={2}
                        title="Sides"
                        options={settings.sides as iOptionWithGroup[]}
                        value={data.menuItemTypes}
                        onChange={handleChange('menuItemTypes')}
                    />
                )}
                {settings.desserts.length > 0 && (
                    <FilterCheckboxGroupDropdown
                        indexInRow={3}
                        title="Desserts"
                        options={settings.desserts as iOptionWithGroup[]}
                        value={data.menuItemTypes}
                        onChange={handleChange('menuItemTypes')}
                    />
                )}
                {settings.beverages.length > 0 && (
                    <FilterCheckboxGroupDropdown
                        indexInRow={4}
                        title="Beverages"
                        options={settings.beverages}
                        value={data.menuItemTypes}
                        onChange={handleChange('menuItemTypes')}
                    />
                )}
            </div>
            <div className="AdminFilters__section">
                <AdditionalFilters
                    addKeywords={(words: any[]) => handleChange('customWords')([
                        ...data.customWords,
                        ...words,
                    ])}
                    removeKeyword={(name: string) => {
                        handleChange('customWords')(data.customWords.filter(i => !equalInLC(i.name, name)));
                    }}
                    keywords={settings.words}
                    words={data.customWords}
                    fullLayout
                    handleChange={(field: string, value: any) => {
                        if (field === 'words') {
                            handleChange('customWords')(value);
                        } else {
                            handleChange(field as keyof iFilterValue)(+value)
                        }
                    }}
                    minPrice={data.minPrice}
                    maxPrice={data.maxPrice}
                    currentCurrency={currentCurrency}
                    style={{ width: '100%' }}
                />
            </div>
            <SectionTitle>Attributes settings</SectionTitle>
            <div className="AdminFilters__section">
                {settings.dayParts.length > 0 && (
                    <FilterCheckboxGroup
                        title="Dayparts"
                        options={settings.dayParts}
                        value={data.dayParts}
                        onChange={handleChange('dayParts')}
                    />
                )}
                <div className="AdminFilters__column size-l">
                    <div>Catering</div>
                    <br/>
                    <CheckboxInput
                        isSmall
                        className="attributes__checkbox"
                        onChange={() => {
                            switch (data.catering) {
                                case false: {
                                    handleChange('catering')(null);
                                    break;
                                }
                                default: {
                                    handleChange('catering')(false);
                                    break;
                                }
                            }
                        }}
                        labelText="Catering Menu Items"
                        isChecked={data.catering !== false}
                    />
                    <CheckboxInput
                        isSmall
                        className="attributes__checkbox"
                        onChange={() => {
                            switch (data.catering) {
                                case true: {
                                    handleChange('catering')(null);
                                    break;
                                }
                                default: {
                                    handleChange('catering')(true);
                                    break;
                                }
                            }
                        }}
                        labelText="Regular Menu Items"
                        isChecked={data.catering !== true}
                    />
                </div>
                <div className="AdminFilters__column size-xl">
                    <div>Additional Filters</div>
                    <br/>
                    <ToggleInput
                        className="attributes__radio"
                        labelText="Items with Healthy terms"
                        isChecked={data.healthyDining}
                        disabled={!isHealthyTermsEnabled}
                        onToggle={() => handleChange('healthyDining')(!data.healthyDining)}
                    />
                    <ToggleInput
                        className="attributes__radio"
                        labelText="Items with Plant Based Alternatives"
                        isChecked={data.plantBased}
                        disabled={!isPlantBasedEnabled}
                        onToggle={() => handleChange('plantBased')(!data.plantBased)}
                    />
                    <ToggleInput
                        className="attributes__radio"
                        labelText="Items from Small Plate Menus"
                        isChecked={data.smallPlates}
                        disabled={!isSmallPlatesEnabled}
                        onToggle={() => handleChange('smallPlates')(!data.smallPlates)}
                    />
                    <ToggleInput
                        className="attributes__radio"
                        labelText="Items with Gluten Free term(s)"
                        isChecked={data.glutenFree}
                        disabled={!isGlutenFreeEnabled}
                        onToggle={() => handleChange('glutenFree')(!data.glutenFree)}
                    />
                    <ToggleInput
                        className="attributes__radio"
                        labelText="Items on Kid's Menus or portioned for Kids"
                        isChecked={data.kidMenus}
                        disabled={!isKidMenusEnabled}
                        onToggle={() => handleChange('kidMenus')(!data.kidMenus)}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminFilters;