import React, { useState, useReducer, useMemo, useCallback, useContext } from "react";
import {connect} from "react-redux";
import Table from "@components/Table/Table";
import "./ByoTableContainer.scss";
import KeywordNameCell from "@components/Table/KeywordNameCell";
import {setField, deleteKeyword, paintKeyword, setData} from "@actions/byo.actions";
import {
    tableAvailableSlicesSelector,
    tableDataSelector,
} from "@selectors/byo.selectors";
import {addNotification} from "@actions/notifications.actions";
import TableHeaderCell from './TableHeaderCell';
import {
    filtersDataSelector,
    filtersForBackEndSelector, isAttributesChangedSelector, isMenuItemsChangedSelector,
    isVenuesChangedSelector
} from "@selectors/filters.selectors";
import MenuExamplesPopup from "../MenuExamplesPopup/MenuExamplesPopup";
import { exportByoMenuExamples, getByoMenuExamples, requestAccess } from "@core/api";
import { GlobalContext } from "@components/App/App.context";

function ByoTableContainer(
    {
        tableData,
        activeValuesType,
        setActiveValuesType,
        deleteKeyword,
        isLoading,
        keywords,
        setSortingData,
        paint,
        filtersForBackEnd,
        activeKeywordId,
        activate,
        isUsa,
        rawFiltersData,
        activeTabId,
        activeAnalyticsType,
        isThemeLightEnable,
        slices,
        currentCountry,
        addNotification,
        setPastGrowthSliceId,
    }
) {
    const { getRem } = useContext(GlobalContext);
    const [isMenuExamplesPopupOpened, setIsMenuExamplesPopupOpened] = useState(false);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
    const [menuExamplesWordData, setMenuExamplesWordData] = useReducer((state, action) => ({...action}), {});
    const [menuExamplesSliceData, setMenuExamplesSliceData] = useReducer((state, action) => ({...action}), {});

    const tableKeywords = tableData.keywords;

    const handleCellMouseEnter = (index) => {
        setHoveredRowIndex(index);
    };
    const handleCellMouseLeave = (index) => {
        setHoveredRowIndex(index);
    };

    const styles = {
        height: tableKeywords.length > 0 ? `${getRem(tableKeywords.length * 40 + 85)}px` : `${getRem(50)}px`,
    };

    const openMenuExamples = (wordIndex, slice) => {
        if ( isLoading ) return;
        setIsMenuExamplesPopupOpened(true);
        setMenuExamplesWordData(tableData.keywords[wordIndex].keyword);
        setMenuExamplesSliceData(slice);
    };

    const fetchMenuExamples = useMemo(() => getByoMenuExamples.bind(null, {
        mainFilter: {
            word: menuExamplesWordData,
            filters: filtersForBackEnd,
        },
        sliceParameter: {
            id: menuExamplesSliceData.id,
            sliceType: menuExamplesSliceData.sliceId,
            type: activeAnalyticsType,
        },
    }), [menuExamplesWordData, filtersForBackEnd, menuExamplesSliceData, activeAnalyticsType]);

    const fetchMenuExamplesExport = useMemo(() => exportByoMenuExamples.bind(null, {
        customerFilter: {
            mainFilter: {
                word: menuExamplesWordData,
                filters: filtersForBackEnd,
            },
            sliceParameter: {
                id: menuExamplesSliceData.id,
                sliceType: menuExamplesSliceData.sliceId,
                type: activeAnalyticsType,
            },
        },
        fullFilter: {...rawFiltersData},
    }), [menuExamplesWordData, filtersForBackEnd, menuExamplesSliceData, activeAnalyticsType]);

    const classListBtn1 = new Set([
        "table-settings__btn",
        activeValuesType === 1 ? "table-settings__btn--active" : "",
        activeTabId === 3 ? "table-settings__btn--disabled" : ""
    ]);

    const classListBtn2 = new Set([
        "table-settings__btn",
        activeValuesType === 2 ? "table-settings__btn--active" : "",
        activeTabId === 3 ? "table-settings__btn--disabled" : ""
    ]);

    function getBaseSizeTitle() {
        switch (activeAnalyticsType) {
            case 1:
                return 'Number of restaurants';
            case 2:
                return 'Number of items';
            case 3:
                return 'Median price';
            default: {
                return 'Base size';
            }
        }
    }

    const handleCloseExamplesPopup = useCallback(() => {
        setIsMenuExamplesPopupOpened(false);
    }, [])

    const activeSliceId = slices.findIndex(({isActive}) => isActive);

    const handleRequestAccessButtonClick = () => {
        requestAccess(currentCountry, 'BYO');
        addNotification({
            text: "Request sent",
            duration: 4000,
        });
    };

    return (
        <div
            className="byo-table-container"
            style={styles}
        >
            <Table
                isLoading={isLoading}
                isFirstColumnFixed={true}
                hasFlavorPermission={tableData.hasFlavorPermission}
                activeSliceId={activeSliceId}
                requestFlavorAccess={handleRequestAccessButtonClick}
                data={[
                    {
                        className: 'table__col--wide table__col--sticky',
                        id: 'first',
                        cells: [
                            {
                                textAlign: 'left',
                                content: (
                                    <>
                                        <button
                                            className={Array.from(classListBtn1).join(" ")}
                                            onClick={() => setActiveValuesType(1)}
                                        >
                                            %
                                        </button>
                                        <button
                                            className={Array.from(classListBtn2).join(" ")}
                                            onClick={() => setActiveValuesType(2)}
                                        >
                                            #
                                        </button>
                                    </>
                                ),
                                isHeader: true,
                            },
                            ...tableKeywords.map((keyword, index) => ({
                                options: [
                                    'first white',
                                    index === tableKeywords.length - 1 ? 'no-bd' : '',
                                    keyword.name.toLowerCase() === activeKeywordId ? 'a hl' : '',
                                    hoveredRowIndex === index ? 'hl' : '',
                                ],
                                content: (
                                    <KeywordNameCell
                                        key={index}
                                        text={keyword.content}
                                        name={keyword.name}
                                        id={keyword.id}
                                        remove={deleteKeyword}
                                        keywords={keywords}
                                        extensions={keyword.wordExtensions || []}
                                        paint={paint}
                                        activate={activate}
                                        activeKeywordId={activeKeywordId}
                                        viewMenuExamples={() => openMenuExamples(index, keyword.menuExamplesData)}
                                        isFoodProfile={keyword.isFoodProfile}
                                        isUsa={isUsa}
                                        isLoading={isLoading}
                                        chartData={isUsa ? {
                                            hasPrediction: false,
                                            chartsCount: 1,
                                            chartInfo: [{name: '', color: keyword.color || (!isThemeLightEnable ? '#fff' : '#788390')}],
                                            data: keyword.chartData.map((item) => ({
                                                label: +item.name,
                                                values: [+item.valueNumeric],
                                            })),
                                        } : {
                                            chartInfo: [{name: '', color: keyword.color || (!isThemeLightEnable ? '#fff' : '#788390')}],
                                            data: [],
                                        }}
                                        onMouseEnter={() => handleCellMouseEnter(index)}
                                        onMouseLeave={handleCellMouseLeave}
                                    />
                                ),
                            })),
                            tableKeywords.length > 0
                                ? {
                                    options: ['dt'],
                                    content: getBaseSizeTitle(),
                                    textAlign: 'left',
                                    isFooter: true,
                                }
                                : null,
                        ],
                    },
                    ...tableData.columns.map((columnHeader, colIndex) => {
                        if (columnHeader.isCopyTableOnly) return null;
                        return ({
                            className: columnHeader.isMedium ? 'table__col--medium' : '',
                            id: columnHeader.id,
                            cells: [
                                {
                                    isHeader: true,
                                    options: [
                                        'hv',
                                        columnHeader.isDark ? 'darken' : '',
                                    ],
                                    onClick: () => {
                                        if (columnHeader.sortIconType) {
                                            setSortingData({
                                                sortingColumn: colIndex,
                                                sortingDirection: columnHeader.sortIconType === 'asc' ? 'desc' : 'asc',
                                            });
                                        } else {

                                            setSortingData({
                                                sortingColumn: colIndex,
                                                sortingDirection: 'desc',
                                            });
                                        }
                                    },
                                    content: (
                                        <TableHeaderCell
                                            index={colIndex}
                                            {...columnHeader}
                                            onOptionClick={setPastGrowthSliceId}
                                            setSortingData={setSortingData}
                                        />
                                    )
                                },
                                ...tableKeywords.map(
                                    (item, index) => {
                                        let content = '';
                                        if (tableData.values[index] && tableData.values[index][colIndex]) {
                                            content = tableData.values[index][colIndex].content;
                                        }
                                        return {
                                            options: [
                                                colIndex > 0 ? 'hlhv' : '',
                                                colIndex === tableData.columns.length - 1 ? 'last' : '',
                                                index === tableKeywords.length - 1 ? 'no-bd' : '',
                                                tableKeywords[index].name.toLowerCase() === activeKeywordId ? 'hl' : '',
                                                columnHeader.isDark ? 'darken' : '',
                                                content === '' ? 'load' : '',
                                                hoveredRowIndex === index ? 'hl' : '',
                                            ],
                                            content,
                                            data: tableData.values[index][colIndex],
                                            onClick: colIndex > 0
                                                ? openMenuExamples.bind(null, index, tableData.values[index][colIndex])
                                                : () => {
                                                }
                                            ,
                                            onMouseEnter: () => handleCellMouseEnter(index),
                                            onMouseLeave: handleCellMouseLeave,
                                        }
                                    }
                                ),
                                {
                                    ...tableData.baseSizes[colIndex],
                                    options: [
                                        'dt',
                                        columnHeader.isDark ? 'darken' : '',
                                    ],
                                },
                            ],
                        })
                    }),
                ]}
            />
            <MenuExamplesPopup
                fetch={fetchMenuExamples}
                fetchExport={fetchMenuExamplesExport}
                isShown={isMenuExamplesPopupOpened}
                close={handleCloseExamplesPopup}
                downloadUrl={'export byo menu examples'}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    slices: tableAvailableSlicesSelector(state),
    activeSlice: state.byo.activeSliceIndex,
    tableData: tableDataSelector(state),
    activeValuesType: state.byo.activeValuesType,
    isLoading: state.byo.isLoading,
    keywords: state.keywords.data,
    take: state.byo.take,
    skip: state.byo.skip,
    filtersForBackEnd: filtersForBackEndSelector(state),
    activeKeywordId: state.byo.activeKeywordId,
    isUsa: state.countries.current === 'USA',
    rawFiltersData: filtersDataSelector(state),
    countries: state.countries.data,
    activeTabId: state.byo.activeAnalyticsType,
    activeAnalyticsType: state.byo.activeAnalyticsType,
    countryCode: state.countries.current,
    isThemeLightEnable: state.app.isThemeLightEnable,
    isVenuesChanged: isVenuesChangedSelector(state),
    isMenuItemsChanged: isMenuItemsChangedSelector(state),
    isAttributesChanged: isAttributesChangedSelector(state),
    currentCountry: state.countries.current,
});

const mapDispatchToProps = dispatch => ({
    setActiveSlice: index => dispatch(setData({
        activeSliceIndex: index,
        sortingColumn: null,
        sortingDirection: 'desc',
    })),
    setActiveValuesType: id => dispatch(setField("activeValuesType", id)),
    deleteKeyword: id => dispatch(deleteKeyword(id)),
    addNotification: notification => dispatch(addNotification(notification)),
    setSortingData: (data) => dispatch(setData(data)),
    setPastGrowthSliceId: (id) => dispatch(setData({ pastGrowthSliceId: id })),
    paint: id => dispatch(paintKeyword(id)),
    activate: id => dispatch(setField('activeKeywordId', id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ByoTableContainer);
