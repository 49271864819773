import { createSelector } from "reselect";
import { SLICES } from "@core/models/ByoTableData";
import { deepCopy, dynamicSort } from "@core/old_helpers";
import { segmentsSortedArray } from "./filters.selectors";

export const chartsSelector = state => deepCopy(state.adminInstantChart.allCharts);
export const chartsCategoriesSelector = state => deepCopy(state.adminInstantChart.allCategories);
export const currentChartSelector = state => deepCopy(state.adminInstantChart.currentInstantChart);
export const chartFiltersSelector = state => deepCopy(state.adminInstantChart.allFilters);
export const defaultRestaurantListIdSelector = state => state.adminInstantChart.allFilters.defaultRestaurantListId;
export const rawDataSelector = state => state.adminInstantChart.allFilters.raw;

export const chartCategoriesToOptions = createSelector(
    chartsCategoriesSelector,
    (allCategories) => allCategories.map(({ id, name }) => ({ id, name })),
);

export const filtersDataSelector = createSelector(
    chartFiltersSelector,
    currentChartSelector,
    (allFilters, currentInstantChart) => {
        if ( !allFilters ) return;
        const currentRestaurantId = () => {
            if (Object.keys(currentInstantChart).length === 0 ) {
                return allFilters.defaultRestaurantListId;
            }

            return currentInstantChart.chart.filter.restaurantListId || allFilters.defaultRestaurantListId
        };

        return allFilters.raw && allFilters.raw.find(({ restaurantListIds }) => restaurantListIds.includes(currentRestaurantId()))
    }
);

export const filtersIsLoadedSelector = createSelector(
    chartFiltersSelector,
    (allFilters) => {
        if ( !allFilters ) return false;
        return allFilters.isDataLoaded
    }
);

export const chartWordsSelector = createSelector(
    chartFiltersSelector,
    (allFilters) => {
        if ( !allFilters ) return [];
        return allFilters.currentWords;
    }
)

export const filtersChartSelector = createSelector(
    filtersDataSelector, currentChartSelector, chartFiltersSelector,
    ((filters, currentInstantChart, allFilters) => {
        if (!filters) {
            return {
                venues: {},
                menuItems: {},
                attributes: {}
            };
        }

        const rawFilters = filters;
        if (!rawFilters.otherFilters) {
            rawFilters.otherFilters = {};
        }
        const {
            minPrice = null,
            maxPrice = null,
            minUnits = null,
            maxUnits = null,
            ethnicSpeciality = [],
        } = rawFilters.otherFilters;

        const { chart: active } = currentInstantChart;
        const itemTypesColumnsOrder = SLICES.find(({ id }) => id === 1006).columnsOrder;
        const result = {
            venues: {
                segments: rawFilters.restaurantFilters.segments.map((item, inx) => ({
                    ...item,
                    isChecked: active && active.filter.segments ? active.filter.segments.includes(item.id) : true,
                })).sort((a, b) => {
                    return segmentsSortedArray.indexOf(a.name.toLowerCase()) - segmentsSortedArray.indexOf(b.name.toLowerCase());
                }),

                regions: rawFilters.restaurantFilters.regions.map((item, inx) => ({
                    ...item,
                    isChecked: active && active.filter.regions ? active.filter.regions.includes(item.id) : true,
                })),

                cuisines: rawFilters.restaurantFilters.cuisines.map((item, inx) => ({
                    ...item,
                    isChecked: active && active.filter.cuisines ? active.filter.cuisines.includes(item.id) : true,
                })),
                restaurants: (active && active.filter.restaurants) ? active.filter.restaurants : [],
                minUnits: active && active.filter.minUnits ? active.filter.minUnits : minUnits,
                maxUnits: active && active.filter.maxUnits ? active.filter.maxUnits : maxUnits
            },
            attributes: {
                dayParts: rawFilters.restaurantFilters.dayParts.map((item) => ({
                    ...item,
                    isChecked: active && active.filter.dayParts ? active.filter.dayParts.includes(item.id) : true,
                })),
                ethnicSpeciality,
                healthyTerms: active ? active.filter.healthyDining : false,
                smallPlates: active ? active.filter.smallPlates : false,
                kidMenus: active ? active.filter.kidMenus : false,
                glutenFree: active ? active.filter.glutenFree : false,
                plantBased: active ? active.filter.plantBased : false,
                catering: active && active.filter.catering ? active.filter.catering : null,
            },
            menuItems: {
                ...rawFilters.menuPartFilterItems.reduce((obj, curr, idx) => {
                    const key = `${curr.name.toLowerCase()}s`;
                    obj[key] = rawFilters.menuPartFilterItems[idx].menuItemTypes.map(i => {
                        return {
                            ...i,
                            isChecked: active ? active.filter.menuItemTypes.includes(i.id) : true
                        }
                    });
                    obj[key].sort((a, b) => {
                        const ai = itemTypesColumnsOrder.indexOf(a.id);
                        const bi = itemTypesColumnsOrder.indexOf(b.id);
                        return ai < bi ? -1 : 1;
                    });
                    return obj;
                }, {}),
                words: active ? active.filter.customWords.sort(dynamicSort("name", "asc")) : [],
                minPrice: active ? active.filter.minPrice : minPrice,
                maxPrice: active ? active.filter.maxPrice : maxPrice,
            },
        }

        return result;
    })
)