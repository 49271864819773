import {deepCopy, getKeywordSettingResult} from "@core/old_helpers";

const FRONT_ID_PREFIX = 'front-';

export const MASTER_OPTION_NAME = 'Master group';

export function getEmptyOptionData(name = '') {
    return {
        id: `${FRONT_ID_PREFIX}${(new Date()).getTime()}`,
        name,
        isMaster: name === MASTER_OPTION_NAME,
        main: {
            isExactMatch: false,
            peerInclude: [],
            include: [],
            exclude: [],
            isTopTrends: false,
            isFoodProfile: false,
            isFlavor: false,
            isLocal: false,
            isInsider: false,
        },
        foodProfile: {
            appetizers: [],
            entrees: [],
            sides: [],
            desserts: [],
            beverages: [],
            dayParts: [],
            filterDescription: '',
        },
        topTrends: {
            descriptors: [],
            flavors: [],
            ingredients: [],
            menuItems: [],
        },
        flavor: {
            topTrendsCategory: null,
            categoryDisplayNameValue: '',
            keywordDisplayNameValue: '',
            macStage: 0,
            isHidePenetrationActive: false,
            isDisplayImageSliderActive: false,
            bulletDescription1: '',
            bulletDescription2: '',
            bulletDescription3: '',
            bulletDescription4: '',
            images: [],
        },
        countryCodes: [],
    };
}

export function normalizeForSidebar(options, activeOptionIndex) {
    return options.map(({id, name, isMaster, countryCodes}, index) => ({
        id,
        name,
        isMaster,
        countryCodes,
        isActive: activeOptionIndex === index,
    }));
}

export function normalizeForDropDown(options) {
    return options.map(({id, name}) => ({id, name}));
}

export function normalizeForDropDownWithoutActive(options, activeOptionIndex) {
    return options
        .map(({id, name}) => ({id, name}))
        .filter((option, index) => index !== activeOptionIndex)
        ;
}

export function normalizeForManageCountries(options) {
    const result = options.reduce((result, option) => {
        option.countryCodes.forEach(
            countryCode => result[countryCode] = option.id
        );
        return result;
    }, {});

    return result;
}


export const optionsDataComparePopupConfig = [
    {
        name: 'Keyword settings',
        section: 'main',
        fields: [
            {
                name: 'Peer include',
                field: 'peerInclude',
            },
            {
                name: 'Include',
                field: 'include',
            },
            {
                name: 'Exclude',
                field: 'exclude',
            },
            {
                name: 'MAC stage',
                field: 'macStage',
                type: 'select',
            },
            {
                name: 'Top Trends',
                field: 'isTopTrends',
            },
            {
                name: 'Food Profile',
                field: 'isFoodProfile',
            },
            {
                name: 'Flavor',
                field: 'isFlavor',
            },
            {
                name: 'Local',
                field: 'isLocal',
            },
            {
                name: 'Search as exact',
                field: 'isExactMatch',
            },
            {
                name: 'Insider',
                field: 'isInsider',
            },
        ]
    },
    {
        name: 'Top Trends settings',
        section: 'topTrends',
        fields: [
            {
                name: 'Descriptors',
                field: 'descriptors',
            },
            {
                name: 'Flavor',
                field: 'flavors',
            },
            {
                name: 'Ingredients',
                field: 'ingredients',
            },
            {
                name: 'Menu items',
                field: 'menuItems',
            },
        ]
    },
    {
        name: 'Food Profile settings',
        section: 'foodProfile',
        fields: [
            {
                name: 'Appetizers',
                field: 'appetizers',
            },
            {
                name: 'Entrees',
                field: 'entrees',
            },
            {
                name: 'Sides',
                field: 'sides',
            },
            {
                name: 'Desserts',
                field: 'desserts',
            },
            {
                name: 'Beverages',
                field: 'beverages',
            },
            {
                name: 'Day Parts',
                field: 'dayParts',
            },
            {
                name: 'Filter description',
                field: 'filterDescription',
            },
        ]
    },
    {
        name: 'Flavor',
        section: 'flavor',
        fields: [
            {
                name: 'Category',
                field: 'topTrendsCategory',
                type: 'select',
            },
            {
                name: 'Category Display Name',
                field: 'categoryDisplayNameValue',
                type: 'settingsInput',
            },
            {
                name: 'Keyword Display Name',
                field: 'keywordDisplayNameValue',
                type: 'settingsInput',
            },
            {
                name: 'Do Not Display Penetration',
                field: 'isHidePenetrationActive',
                type: 'checkbox',
            },
            {
                name: 'Display Image Slider',
                field: 'isDisplayImageSliderActive',
                type: 'checkbox',
            },
            {
                name: 'Bullet description 1',
                field: 'bulletDescription1',
                type: 'textArea',
            },
            {
                name: 'Bullet description 2',
                field: 'bulletDescription2',
                type: 'textArea',

            },
            {
                name: 'Bullet description 3',
                field: 'bulletDescription3',
                type: 'textArea',

            },
            {
                name: 'Bullet description 4',
                field: 'bulletDescription4',
                type: 'textArea',

            },
        ]
    },
];

export function normalizeOptionFromBackEnd(option) {
    const emptyOption = getEmptyOptionData();
    const {main, foodProfile, topTrends, flavor, macStage, ...optionData} = option;
    const {wordExtensions, ...restMain} = main;
    const {filterDescription, ...foodProfileSelects} = foodProfile;
    const {
        flavorCategoryId,
        hidePenetration,
        displayImageSlider,
        snapshotTextBullet1,
        snapshotTextBullet2,
        snapshotTextBullet3,
        snapshotTextBullet4,
        ...restFlavor
    } = flavor;

    Object.keys(foodProfileSelects).forEach(
        selectName => foodProfileSelects[selectName] = foodProfileSelects[selectName].map(({id}) => id)
    );

    Object.keys(topTrends).forEach(
        selectName => topTrends[selectName] = topTrends[selectName].map(({id}) => id)
    );

    return {
        ...optionData,
        name: optionData.isMaster ? MASTER_OPTION_NAME : (optionData.optionName || ''),
        main: {
            ...restMain,
            include: wordExtensions.filter(word => word.wordType === 1),
            exclude: wordExtensions.filter(word => word.wordType === 2),
            peerInclude: wordExtensions.filter(word => word.wordType === 3),
            macStage,
        },
        foodProfile: {
            ...emptyOption.foodProfile,
            ...foodProfileSelects,
            filterDescription,
        },
        topTrends: {
            ...emptyOption.topTrends,
            ...topTrends,
        },
        flavor: {
            ...restFlavor,
            isHidePenetrationActive: hidePenetration,
            isDisplayImageSliderActive: displayImageSlider,
            bulletDescription1: snapshotTextBullet1,
            bulletDescription2: snapshotTextBullet2,
            bulletDescription3: snapshotTextBullet3,
            bulletDescription4: snapshotTextBullet4,
            topTrendsCategory: flavorCategoryId,
        },
    };
}

function mapIdToOption(id, selectOptions) {
    return selectOptions.find(option => option.id === id);
}

export function normalizeOptionForBackEnd(_option, selectsData) {
    const option = deepCopy(_option);
    const {main, optionName, foodProfile, topTrends, flavor, id, name, ...optionData} = option;
    const {peerInclude, include, exclude, macStage, ...restMain} = main;
    const {filterDescription, ...foodProfileSelects} = foodProfile;
    const {
        topTrendsCategory,
        isHidePenetrationActive,
        isDisplayImageSliderActive,
        bulletDescription1,
        bulletDescription2,
        bulletDescription3,
        bulletDescription4,
        ...restFlavor
    } = flavor;

    Object.keys(foodProfileSelects).forEach(
        selectName => foodProfileSelects[selectName] = foodProfileSelects[selectName].map(
            id => mapIdToOption(id, selectsData[selectName])
        )
    );

    Object.keys(topTrends).forEach(
        selectName => topTrends[selectName] = topTrends[selectName].map(
            id => mapIdToOption(id, selectsData[selectName])
        )
    );


    const result = {
        ...optionData,
        macStage,
        main: {
            ...restMain,
            wordExtensions: [
                ...include.map(word => ({...word, wordType: 1})),
                ...exclude.map(word => ({...word, wordType: 2})),
                ...peerInclude.map(word => ({...word, wordType: 3})),
            ],
        },
        foodProfile: {
            ...foodProfileSelects,
            filterDescription,
        },
        topTrends: {
            ...topTrends,
        },
        flavor: {
            ...restFlavor,
            hidePenetration: isHidePenetrationActive,
            displayImageSlider: isDisplayImageSliderActive,
            snapshotTextBullet1: bulletDescription1,
            snapshotTextBullet2: bulletDescription2,
            snapshotTextBullet3: bulletDescription3,
            snapshotTextBullet4: bulletDescription4,
            flavorCategoryId: topTrendsCategory,
        },
    };

    if (`${id}`.indexOf(FRONT_ID_PREFIX) < 0) {
        result.id = id;
    }

    if (!optionData.isMaster) {
        result.optionName = name;
    }

    return result;
}

export function normalizeOptionForCompare(_option, selectsData) {
    const option = deepCopy(_option);
    const {main, optionName, foodProfile, topTrends, flavor, isMaster, ...optionData} = option;
    const {
        isExactMatch,
        isTopTrends,
        isFoodProfile,
        isFlavor,
        isLocal,
        isInsider,
        macStage,
        ...mainSelects
    } = main;

    const {filterDescription, ...foodProfileSelects} = foodProfile;
    const {
        topTrendsCategory,
        images,
        bulletDescription1,
        bulletDescription2,
        bulletDescription3,
        bulletDescription4,
        isHidePenetrationActive,
        isDisplayImageSliderActive,
        keywordDisplayNameValue,
        categoryDisplayNameValue,
        ...restFlavor
    } = flavor;

    Object.keys(topTrends).forEach(
        selectName => topTrends[selectName] = topTrends[selectName].map(
            id => mapIdToOption(id, selectsData[selectName])
        ).map(({name}) => name).sort().join(', ')
    );

    Object.keys(mainSelects).forEach(
        selectName => mainSelects[selectName] = mainSelects[selectName].map(({name}) => name).sort().join(', ')
    );

    Object.keys(foodProfileSelects).forEach(
        selectName => {
            if(isMaster) {
                foodProfileSelects[selectName] = foodProfileSelects[selectName].map(
                    id => mapIdToOption(id, selectsData[selectName])
                ).map(({name}) => name).sort().join(', ')
            } else {
                foodProfileSelects[selectName] = '-'
            }

        }
    );

    const selectedMacStage = selectsData.macStage.find(option => option.id === macStage);
    const selectedTopTrendsCategory = selectsData.topTrendsCategory.find(option => option.id === topTrendsCategory);

    return {
        ...optionData,
        main: {
            isExactMatch: isExactMatch ? 'Yes' : 'No',
            isTopTrends: isTopTrends ? 'Yes' : 'No',
            isFoodProfile: isMaster && isFoodProfile ? getKeywordSettingResult(isFoodProfile) : '-',
            isFlavor: isMaster && isFlavor ? getKeywordSettingResult(isFlavor) : '-',
            isLocal: isMaster && isLocal ? getKeywordSettingResult(isLocal) : '-',
            isInsider: isMaster && isInsider ? getKeywordSettingResult(isInsider) : '-',
            macStage: (isMaster && selectedMacStage) ? selectedMacStage.name : '-',
            ...mainSelects
        },
        foodProfile: {
            ...foodProfileSelects,
            filterDescription: !isMaster ? '-' : filterDescription,
        },
        topTrends: {
            ...topTrends,
        },
        flavor: {
            ...restFlavor,
            keywordDisplayNameValue: isMaster && keywordDisplayNameValue ? keywordDisplayNameValue : '-',
            bulletDescription1: isMaster && bulletDescription1 ? bulletDescription1 : '-',
            bulletDescription2: isMaster && bulletDescription2 ? bulletDescription2 : '-',
            bulletDescription3: isMaster && bulletDescription3 ? bulletDescription3 : '-',
            bulletDescription4: isMaster && bulletDescription4 ? bulletDescription4 : '-',
            isHidePenetrationActive: isMaster && isHidePenetrationActive ? 'Yes' : '-',
            isDisplayImageSliderActive: isMaster && isDisplayImageSliderActive ? 'Yes' : '-',
            topTrendsCategory: (isMaster && selectedTopTrendsCategory) ? selectedTopTrendsCategory.name : '-',
            categoryDisplayNameValue: isMaster && categoryDisplayNameValue ? categoryDisplayNameValue : '-',

        },

    };
}
