import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect } from "react-redux";
import ByoTableContainer from "@containers/ByoTableContainer/ByoTableContainer";
import ChartContainer from "@containers/ChartContainer/ChartContainer";
import SearchField from "@components/SearchField/SearchField";
import {
    copyTableDataSelector,
    pairedFlavorsDataSelector,
    searchAutocompleteSelector,
    tableAvailableSlicesSelector
} from "@selectors/byo.selectors";
import {
    setField as setByoField,
    submitSearch,
    onLoad,
    onUnload, setData,
} from "@actions/byo.actions";
import { chartDataSelector } from "@selectors/byo.selectors";
import { get as getByoSettingsData } from "@actions/byoSettings.actions";
import FooterContainer from '@containers/FooterContainer/FooterContainer'
import FooterRightContent from "./FooterRightContent"
import RestaurantsMenu from "@components/RestaurantsMenu/RestaurantsMenu";
import LeftFooterContent from "@components/LeftFooterContent/LeftFooterContent";
import Page from "@components/Page/Page";
import Tabs from "@components/Tabs/Tabs";
import ProgressPanel from "@components/ProgressPanel/ProgressPanel";
import { addNotification } from "@actions/notifications.actions";
import "./byo.styles.scss";
import { copyToClipboard } from "@core/old_helpers";
import Loader from "@components/Loader/Loader";
import { Routes } from '@routes';
import { useLocation, useNavigate } from 'react-router-dom';

const RESTAURANTS_LISTS_WITHOUT_CHART = [ 1394 ];

const byoTopChartItems = [
    {
        name: 'Penetration',
        id: 1,
        tooltip: '% of restaurants',
    },
    {
        name: 'Incidence',
        id: 2,
        tooltip: '% of items',
    },
    {
        name: 'Price',
        id: 3,
        tooltip: 'Median price',
    },
];

const Byo = (
    {
        submitSearch,
        searchAutocomplete,
        searchQuery,
        setSearchQuery,
        chartData,
        searchError,
        getData,
        onLoad,
        onUnload,
        slices,
        setActiveSlice,
        setAnalyticsType,
        activeTabId,
        addNotification,
        hasChart,
        isLoading,
        noData = false,
        data,
        copyTableData,
        currentCountry,
        pairedFlavorsData,
        isInstantChartLoading,
    }
) => {
    let location = useLocation();
    let history = useNavigate();
    const [ isShown, setIsShown ] = useState(false);
    const footerRightContent = <FooterRightContent isAbleCleanBtn={true}/>;

    // useEffect(() => {
    //     if (isInstantChart) {
    //         history(`/byo?words=${encodeURIComponent(instantChartKeywordsArray.join(','))}`);
    //     }
    // }, [ currentCountry ]);

    useEffect(() => {
        getData();
        onLoad();
        setIsShown(true);
        return () => {
            onUnload();
        }
    }, [location]);

    useEffect(() => {
        if (noData) history(Routes.Home);
    }, [ noData ]);

    const getTopClassnames = (count) => {
        switch (count) {
            case 1:
                return 'is-large';
            case 2:
                return 'is-medium';
            case 3:
                return 'is-small';
            default:
                return ''
        }
    };

    const activeSliceId = slices.findIndex(({isActive}) => isActive);

    return (
            <Page isWrapped={true} title="Build Your Own">
                <div className="byo">
                    <Loader isShown={isInstantChartLoading} className="byo__loader"/>
                    <div className={`byo__top ${getTopClassnames(data.length)}`}>
                        <CSSTransition in={isShown} classNames="appears1" timeout={3000} unmountOnExit>
                            <div className="byo__top-header">
                                <div className="byo__top-left">
                                    <div className={`byo__chart-top ${hasChart ? 'byo__chart-top--has-chart' : ''}`}>
                                        <Tabs
                                            data={byoTopChartItems}
                                            handleClick={setAnalyticsType}
                                            activeItemId={activeTabId}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                        {hasChart && (
                            <div className={`byo__top-content`}>
                                <CSSTransition in={isShown} classNames="appears2" timeout={3000} unmountOnExit>
                                    <div className="byo__chart byo__top-left">
                                        <ChartContainer/>
                                    </div>
                                </CSSTransition>
                                <CSSTransition in={isShown} classNames="appears3" timeout={3000} unmountOnExit>
                                    <div className="byo__sidebar byo__top-right">
                                        <ProgressPanel
                                            data={pairedFlavorsData}
                                            isLoading={isLoading}
                                            hasArrows={data.length > 1}
                                        />
                                    </div>
                                </CSSTransition>
                            </div>
                        )}
                    </div>
                    <div className="byo__table">
                        <CSSTransition in={isShown} classNames="appears4" timeout={3000} unmountOnExit>
                            <div className="byo__table-top">
                                <SearchField
                                    searchQuery={searchQuery}
                                    searchError={searchError}
                                    setSearchQuery={setSearchQuery}
                                    onEnter={submitSearch}
                                    autoCompleteData={searchAutocomplete}
                                    chartsCount={chartData.chartsCount}
                                    isDisabled={isInstantChartLoading}
                                    modifiers={["static"]}
                                    isUpperCase
                                />
                                <Tabs
                                    data={
                                        slices.map((item, inx) => ({
                                            name: item.name.toLowerCase(),
                                            id: inx,
                                            tooltip: item.tooltip,
                                        }))
                                    }
                                    activeItemId={activeSliceId}
                                    handleClick={id => setActiveSlice(id)}
                                    title={'show by:'}
                                />
                                <button
                                    className="copy-table-btn"
                                    onClick={() => {
                                        copyToClipboard(copyTableData);

                                        const isSuccess = copyTableData.split('\t').filter(e => e.trim()).length > 1;

                                        addNotification({
                                            text: isSuccess ? "Successfully copied to your clipboard" : "Something went wrong",
                                            duration: 4000,
                                            error: !isSuccess
                                        })

                                    }}
                                >
                                    COPY TABLE
                                </button>
                            </div>
                        </CSSTransition>
                        <CSSTransition in={isShown} classNames="appears5" timeout={3000} unmountOnExit>
                            <ByoTableContainer/>
                        </CSSTransition>
                    </div>
                    <FooterContainer footerRightContent={footerRightContent}>
                        <LeftFooterContent/>
                        <RestaurantsMenu/>
                    </FooterContainer>
                </div>
            </Page>
        );
};

const mapStateToProps = state => ({
    filters: state.filters,
    searchQuery: state.byo.searchQuery,
    searchError: state.byo.searchError,
    searchAutocomplete: searchAutocompleteSelector(state),
    chartData: chartDataSelector(state),
    slices: tableAvailableSlicesSelector(state),
    activeTabId: state.byo.activeAnalyticsType,
    hasChart: !RESTAURANTS_LISTS_WITHOUT_CHART.includes(state.filters.activeRestaurantListId),
    pairedFlavorsData: pairedFlavorsDataSelector(state),
    isLoading: state.byo.isLoading,
    // activePairedWithWordIndex: state.byo.activePairedWithWordIndex,
    data: state.byo.data,
    noData: state.byo.noData,
    copyTableData: copyTableDataSelector(state),
    currentCountry: state.countries.current,
    isInstantChartLoading: state.byo.isInstantChartLoading,

});

const mapDispatchToProps = dispatch => ({
    getData: () => dispatch(getByoSettingsData()),
    setSearchQuery: value => {
        dispatch(setByoField("searchQuery", value));
        dispatch(setByoField("searchError", false));
    },
    submitSearch: () => dispatch(submitSearch()),
    onLoad: () => dispatch(onLoad()),
    onUnload: () => dispatch(onUnload()),
    setActiveSlice: index => dispatch(setData({
        activeSliceIndex: index,
        sortingColumn: null,
        sortingDirection: 'desc',
    })),
    setAnalyticsType: id => dispatch(setByoField("activeAnalyticsType", id)),
    addNotification: notification => dispatch(addNotification(notification)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Byo);